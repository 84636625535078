import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useForgotPasswordService = () => {
  const { http } = useAxiosHTTPRequest();

  const sendCode = () => {};

  const sendResetPasswordLink = async (email: string) => {
    const response = http.post(
      "/reset-password",
      { email },
      {
        method: "POST",
      }
    );

    return response;
  };

  return {
    sendCode,
    sendResetPasswordLink,
  };
};
