import { AnimatePresence } from "framer-motion";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { MainLayout } from "../layouts/MainLayout/MainLayout";
import { MessageCenterLayout } from "../layouts/MessageCenterLayout/MessageCenterLayout";
import { SettingsLayout } from "../layouts/SettingsLayout/SettingsLayout";
import { Notifications } from "../Pages/MessageCenter/Notifications/Notifications";
// import { SendAndSchedule } from "../Pages/MessageCenter/SendAndSchedule/SendAndSchedule";
import { Apps } from "../Pages/Settings/Apps/apps";
import { LoginSecurity } from "../Pages/Settings/LoginSecurity/loginSecurity";
import { MyAccount } from "../Pages/Settings/MyAccount/myAccount";
import { Overview } from "../Pages/Settings/Overview/overview";
// import { EventList } from "../Pages/EventList/eventList";
// import { OnlineCampaign } from "../Pages/MessageCenter/OnlineCampaign/onlineCampaign";
// import { Organizations } from "../Pages/Settings/Organizations/organizations";
// import { Users } from "../Pages/Settings/Users/organizationUsers";

export const AuthenticatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<MainLayout />}>
          {/* <Route path="/event-list" element={<EventList />} /> */}

          <Route path="/settings" element={<SettingsLayout />}>
            <Route
              path="/settings"
              element={<Navigate to="/settings/overview" />}
            />
            <Route path="/settings/overview" element={<Overview />} />
            <Route path="/settings/my-account" element={<MyAccount />} />
            <Route path="/settings/login-security" element={<LoginSecurity />} />
            <Route path="/settings/apps" element={<Apps />} />

            {/*
              <Route path="/settings/users" element={<Users />} />
              <Route path="/settings/organizations" element={<Organizations/> }/>
            */}
          </Route>

          <Route path="/message-center" element={<MessageCenterLayout />}>
            <Route
              path="/message-center/notifications"
              element={<Notifications />}
            />
            {/* <Route
              path="/message-center/send-schedule"
              element={<SendAndSchedule />}
            /> */}
          </Route>

          <Route path="*" element={<Navigate to="/message-center/notifications" />} />
          <Route path="/" element={<Navigate to="/message-center/notifications" />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
