import styled from "styled-components";

import { PageAnimatedContainer } from "../../../../utils/pageAnimated";

export const StyledDiv = styled(PageAnimatedContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  padding-bottom: 120px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const DivContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const DivCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  @media screen and (min-width: 1000px) {
    gap: 70px;
  }
`;
