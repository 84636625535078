import {
  GLEmptyList,
  GLList,
  GLPaginationComponent,
  useI18n,
} from "@group-link-one/grouplink-components";

import EmptyListImage from "../../../../images/MessageCenter/empty_art_light.svg";
import { ApresentationNotificationsCards } from "../Cards/ApresentationNotificationsCards";
// import { useListNotifications } from "../../../../Services/messageCenterService/useListNotifications";
import { NotificationsContentContainer } from "./NotificationsContentStyle";
import { IPP_NOTIFICATIONS, useNotificationsContext } from "./useNotificationsContent";

export function NotificationsContent() {
  const { t } = useI18n()
  const {
    tabs,
    activeTab,
    currentPage,
    handleOnPaginate,
    notificationsPaginated,
    searchTerm,
    notifications: notificationsDelivereds,
    notificationsIsLoading: notificationsDeliveredsIsLoading,
  } = useNotificationsContext();

  return (
    <NotificationsContentContainer>
      {notificationsDelivereds && notificationsDelivereds.length === 0 ? (
        <div className="online-campaign-empty-list">
          <GLEmptyList
            title={t("messageCenter.notifications.emptyList.title")}
            description={t("messageCenter.notifications.emptyList.description")}
            icon={<img src={EmptyListImage} alt="" />}
            hasBorder={true}
          />
        </div>
      ) : (
        <GLList
          listName="Notifications"
          isLoading={notificationsDeliveredsIsLoading}
          content={
            <ApresentationNotificationsCards notifications={notificationsPaginated} />
          }
          tabActive={activeTab?.name || ""}
          tabs={tabs}
          filters={
            <GLPaginationComponent
              itemsPerPage={IPP_NOTIFICATIONS}
              nextPage={handleOnPaginate}
              previousPage={handleOnPaginate}
              totalItems={searchTerm.length > 0 ? notificationsDelivereds.length : tabs[0].count!}
              currentPage={currentPage}
            />
          }
        />
      )}

    </NotificationsContentContainer>
  );
}
