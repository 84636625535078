import { GLMainContent, useI18n } from "@group-link-one/grouplink-components";

import { Content } from "./Content/loginSecurityContent";

export const LoginSecurity = () => {
  const { t } = useI18n()

  return (
    <GLMainContent
      subtitle={t("pages.settings.title")}
      title={t("pages.settings.loginSecurity")}
      content={<Content />}
    />
  );
};
