import { useAuth } from "@group-link-one/gl-auth";
import { useI18n, useToast } from "@group-link-one/grouplink-components";
import { useState } from "react";
import * as yup from "yup";

import { useMyAccountService } from "../../../Services/myAccountService/useMyAccountService";
import { FORM_INITIAL_STATE } from "./types";


export function useMyAccount() {
  const { user } = useAuth();
  const { addToast } = useToast()
  const { t } = useI18n()
  const [form] = useState(FORM_INITIAL_STATE);
  const { updateMyAccount } = useMyAccountService();

  const handleUpdateAccountInformation = async (
  ) => {
    updateMyAccount().then((res) => {
      if (res.status === 204) {
        addToast({
          title: t("myAccount.toast.title"),
          message: t("myAccount.toast.message"),
          type: "success"
        })
      }
    }).catch((err) => {
      console.log(err)
      addToast({
        title: t("toast.error.title"),
        message: t("toast.error.message"),
        type: "error",
      });
    })
  };

  const myAccountFormSchema = yup.object().shape({
    name: yup.string().min(1, t("myAccount.errors.name")).default(user?.name),
    last_name: yup.string().min(1, t("myAccount.errors.last_name")).default(user?.last_name),
    email: yup.string().default(user?.email).email(t("myAccount.errors.email")),
    phone: yup.string().default(user?.phone).min(8, t("myAccount.errors.phone")),
    job_title: yup.string().default(user?.job_title).min(1, t("myAccount.errors.job_title")),
    organization: yup.string().default(user?.org.name).min(1, t("myAccount.errors.organization")),
  });

  return {
    form,
    handleUpdateAccountInformation,
    myAccountFormSchema
  };
}
