import { GLMainContent, useI18n } from "@group-link-one/grouplink-components";

import { MyAccountForm } from "./content";

export const MyAccount = () => {
  const { t } = useI18n()
  return (
    <GLMainContent
      content={<MyAccountForm />}
      subtitle={t("pages.settings.title")}
      title={t("pages.settings.myAccount")}
    />
  );
};
