import { GLAppCard } from "@group-link-one/grouplink-components";

import { useAppContext } from "../Context/useAppContext";
import { IApp } from "../types/app";

interface IAppCards {
  apps: IApp[] | undefined;
}

export const AppCards = ({ apps }: IAppCards) => {
  const { setOpenModal, setAppData } = useAppContext();

  const onClickAppCard = ({ id, name }: IApp) => {
    setAppData({ id, name });
    setOpenModal(true);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      {apps?.map((app: IApp) => (
        <GLAppCard
          name={app.name}
          android={app.android}
          ios={app.ios}
          onDelete={() => onClickAppCard(app)}
          key={app.id}
          created_at={app.created_at}
        />
      ))}
    </div>
  );
};
