import { GL_COLORS } from "@group-link-one/grouplink-components";
import styled from "styled-components";

import { PageAnimatedContainer } from "../../../../utils/pageAnimated";

export const AppContainer = styled(PageAnimatedContainer)`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const ModalAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 20px;

  @media screen and (min-width: 1000px) {
    display: grid;
    gap: 250px;
    grid-template-columns: 1fr 1fr;

    .tips-container {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      max-height: 300px;
    }
  }
`;

export const FormAppContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .form-content {
    outline: none;
  }

  footer {
    display: flex;
    align-self: center;
    gap: 20px;

    button {
      font-weight: 600;
    }

    > button:nth-child(1) {
      width: 100px;
      border: 2px solid ${GL_COLORS.NAVY};
      color: ${GL_COLORS.NAVY};
    }

    > button:nth-child(2) {
      width: 215px;
    }
  }

  @media screen and (min-width: 768px) {
    .form-content {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    footer {
      align-self: flex-end;
    }
  }
`;
