import styled from "styled-components";

import { PageAnimatedContainer } from "../../../utils/pageAnimated";

export const FieldContainer = styled.div`
  .button__update__profile {
    margin-top: 30px;
  }

  @media screen and (min-width: 768px) {
    .button__update__profile {
      margin-top: 40px;
    }
  }
`;
export const Column = styled.div``;
export const LeftColumn = styled.form``;

export const RightColumn = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Grid = styled(PageAnimatedContainer)`
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Section = styled.section`
  padding-bottom: 30px;

  > span {
    display: block;
    padding-bottom: 40px;
  }
`;

export const SelectField = styled.div`
  padding-bottom: 10px;
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Field = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  flex-direction: row;
  gap: 20px;
  width: 100%;

  > button {
    justify-self: end;
    width: 50%;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    > button {
      width: 100%;
    }
  }
`;

export const MobileFriendlyField = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;
