import {
  GL_ANDROID,
  GL_APPLE,
  GL_COLORS,
  GL_SMARTPHONE,
  GLAppForm,
  GLButton,
  GLDropFile,
  GLTextField,
  GLTipsCard,
  useI18n,
  useToast,
} from "@group-link-one/grouplink-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useAppSchema } from "../schema/app.schema";
import { IAppData } from "../types/app";
import { useApp } from "../useApp";
import { FormAppContainer, ModalAppContainer } from "./Style";

interface IModalAppContentProps {
  setOpen: (value: boolean) => void;
}

export const ModalAppContent = ({ setOpen }: IModalAppContentProps) => {
  const {
    registerApp,
    androidOpen,
    iosOpen,
    setAndroidOpen,
    setIosOpen,
    androidSelected,
    setAndroidSelected,
    iosSelected,
    setIosSelected,
  } = useApp();
  const { t } = useI18n();

  const { addToast } = useToast();

  const { schema } = useAppSchema({ androidSelected, iosSelected });

  const {
    handleSubmit,
    setValue,
    register,
    reset,
    watch,
    formState,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fileIOS = watch("ios.p8_key_file");
  const fileANDROID = watch("android.credentials_json");

  const handleAppSubmit = async (data: IAppData) => {
    registerApp(data)
      .then(() => {
        addToast({
          title: "App created",
          message: "Application created successfully",
          type: "success",
        });
        setAndroidOpen(false);
        setIosOpen(false);
        setAndroidSelected(false);
        setIosSelected(false);
        setOpen(false);

        return;
      })
      .catch((err) => {
        const { response } = err;
        if (response.status === 400) {
          return addToast({
            title: "Action blocked",
            message: response.data.message,
            type: "error",
          });
        }
        return addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        android: {
          credentials_json: null,
          sender_id: null,
          pkg_name_android: null,
        },
        ios: {
          p8_key_file: null,
          key_id: null,
          pkg_name_ios: null,
          team_id: null,
        },
        name: "",
      });
    }
  }, [formState, isSubmitSuccessful, reset]);

  return (
    <>
      <p style={{ marginTop: "30px" }}>
        {t("overview.apps.modal.modalDetailed.description")}
      </p>
      <ModalAppContainer>
        <FormAppContainer onSubmit={handleSubmit(handleAppSubmit)}>
          <GLTextField
            title={t("overview.apps.modal.modalDetailed.form.appName")}
            type="text"
            defaultValue={""}
            placeholder={t("overview.apps.modal.modalDetailed.form.appName")}
            failure={!!errors.name?.message}
            failureMessage={errors.name?.message}
            {...register("name")}
          />

          <label style={{ marginTop: "10px" }}>
            {t("overview.apps.modal.modalDetailed.form.os.label")}
          </label>

          <GLAppForm
            onSwitchSelected={() => {
              setAndroidSelected(!androidSelected)
              setAndroidOpen(!androidOpen)
              if (androidSelected) {
                setAndroidSelected(false)
                setAndroidOpen(false)
              }
              setIosOpen(false)
            }
            }
            switchSelected={androidSelected}
            onClick={() => {
              setAndroidOpen(!androidOpen);
              setIosOpen(false);
            }}
            formIcon={
              <GL_ANDROID
                size={20}
                fill={
                  androidOpen ? GL_COLORS.ACCENT_COLOR : GL_COLORS.FONT_COLOR
                }
              />
            }
            formName="Android"
            error={!!errors.android}
            open={androidOpen}
            content={
              <>
                <div className="form-content">
                  <GLTextField
                    onFocus={function () {
                      setAndroidOpen(true);
                      setIosOpen(false);
                    }}
                    title={t(
                      "overview.apps.modal.modalDetailed.form.os.packageName"
                    )}
                    type="text"
                    defaultValue={""}
                    placeholder="com.company.appname"
                    failure={!!errors.android?.pkg_name_android?.message}
                    failureMessage={errors.android?.pkg_name_android?.message}
                    {...register("android.pkg_name_android")}
                  />

                  <GLTextField
                    title={t(
                      "overview.apps.modal.modalDetailed.form.os.firebase"
                    )}
                    type="text"
                    defaultValue={""}
                    placeholder="xxxxxxxxxxxx"
                    failure={!!errors.android?.sender_id?.message}
                    failureMessage={errors.android?.sender_id?.message}
                    {...register("android.sender_id")}
                  />
                </div>
                <GLDropFile
                  title={t(
                    "overview.apps.modal.modalDetailed.form.os.jsonLabel"
                  )}
                  maximumSizeInMegabyte={25}
                  supportedFormats={{
                    "application/json": [".json"],
                  }}
                  supportedFormatsMessage="JSON"
                  onChange={(e: any) => {
                    setValue("android.credentials_json", e);
                  }}
                  failure={!!errors.android?.credentials_json?.message}
                  isToReset={!!fileANDROID}
                  errorMessage={errors?.android?.credentials_json?.message}
                />
              </>
            }
          />
          <GLAppForm
            onSwitchSelected={() => {
              setIosSelected(!iosSelected)
              setIosOpen(!iosOpen)
              if (iosSelected) {
                setIosSelected(false)
                setIosOpen(false)
              }
              setAndroidOpen(false)
            }
            }
            switchSelected={iosSelected}
            onClick={() => {
              setIosOpen(!iosOpen);
              setAndroidOpen(false);
            }}
            formIcon={
              <GL_APPLE
                size={20}
                fill={iosOpen ? GL_COLORS.ACCENT_COLOR : GL_COLORS.FONT_COLOR}
              />
            }
            formName="iOS"
            error={!!errors.ios}
            open={iosOpen}
            content={
              <>
                <div className="form-content">
                  <GLTextField
                    onFocus={function () {
                      setIosOpen(true);
                      setAndroidOpen(false);
                    }}
                    title={t("overview.apps.modal.modalDetailed.form.os.keyId")}
                    type="text"
                    defaultValue={""}
                    placeholder="xxxxxxx_xxxxxxx"
                    failure={!!errors.ios?.key_id?.message}
                    failureMessage={errors.ios?.key_id?.message}
                    {...register("ios.key_id")}
                  />
                  <GLTextField
                    title={t(
                      "overview.apps.modal.modalDetailed.form.os.teamId"
                    )}
                    type="text"
                    defaultValue={""}
                    placeholder="xxxxxxxxxxxx"
                    failure={!!errors.ios?.team_id?.message}
                    failureMessage={errors.ios?.team_id?.message}
                    {...register("ios.team_id")}
                  />
                </div>
                <GLTextField
                  title={t(
                    "overview.apps.modal.modalDetailed.form.os.bundleId"
                  )}
                  type="text"
                  defaultValue={""}
                  placeholder="com.company.appname"
                  failure={!!errors.ios?.pkg_name_ios?.message}
                  failureMessage={errors.ios?.pkg_name_ios?.message}
                  {...register("ios.pkg_name_ios")}
                />

                <GLDropFile
                  title={t("overview.apps.modal.modalDetailed.form.os.p8Label")}
                  maximumSizeInMegabyte={25}
                  supportedFormats={{
                    "application/p8": [".p8"],
                  }}
                  supportedFormatsMessage="P8"
                  onChange={(e: any) => {
                    setValue("ios.p8_key_file", e);
                  }}
                  failure={!!errors.ios?.p8_key_file?.message}
                  isToReset={!!fileIOS}
                />
              </>
            }
          />

          <footer>
            <GLButton
              type="button"
              variant="outline"
              text={t("overview.apps.modal.modalDetailed.form.buttonCancel")}
              align="center"
              onClick={() => setOpen(false)}
            />
            <GLButton
              type="submit"
              variant="fill"
              text={t("overview.apps.modal.modalDetailed.form.buttonRegister")}
              align="center"
              isLoading={isSubmitting}
            />
          </footer>
        </FormAppContainer>

        <div className="tips-container">
          <GLTipsCard
            icon={<GL_SMARTPHONE size={30} fill={GL_COLORS.FONT_COLOR} />}
            title={t("overview.apps.modal.modalDetailed.tips.title")}
            content={
              <div>
                <p
                  style={{
                    color: GL_COLORS.ACCENT_COLOR,
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  {t("overview.apps.modal.modalDetailed.tips.subtitle")}
                </p>

                <p style={{ fontSize: "16px", color: GL_COLORS.FONT_COLOR }}>
                  {t("overview.apps.modal.modalDetailed.tips.description")}
                </p>
              </div>
            }
          />
        </div>
      </ModalAppContainer>
    </>
  );
};
