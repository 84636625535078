import { GL_COLORS, GL_DELETE, GL_SEARCH, OnSelectMunicipalityProps, OnSelectStateProps } from "@group-link-one/grouplink-components"
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { useDebounceCallback } from "usehooks-ts";

import { GetDivisionsResponse, GetStatesResponse } from "../../../../Services/messageCenterService/useListGeoAdmnistrativeAreas/useListGeoAdmnistrativeAreas.types";
import { useCreateNotificationStore } from "../store/createNotification";
import { useSegmentationStore } from "../store/segmentation";
import { ButtonCleanSearch } from "./segmentationStyle";

export function useSegmentation() {

  const { state: createNotificationState } = useCreateNotificationStore();
  const { actions: segmentationActions, state: segmentationState } = useSegmentationStore();

  const queryClient = useQueryClient()
  const searchRef = useRef<HTMLInputElement>(null);

  function getStateSelectedId(stateCode: string) {
    const states: GetStatesResponse["rows"] | undefined =
      queryClient.getQueryData(["all-states-BRA"]);

    const stateSelectedId = states?.find((state) => state.code === stateCode)?.division.id;

    return stateSelectedId;
  }

  function getMunicipalitySelectedId(municipalityName: string) {
    const municipalities: GetDivisionsResponse["rows"] | undefined =
      queryClient.getQueryData(["municipalities-per-state-BRA", segmentationState.stateSelectedId]);

    const municipalitySelectedId = municipalities?.find((municipality) => municipality.division_name === municipalityName)?.id;


    return municipalitySelectedId;
  }

  function onSelectState({ stateId }: OnSelectStateProps) {
    segmentationActions.setStateSelected(stateId);
    segmentationActions.setStateClicked(stateId);

    const stateIdSelected = getStateSelectedId(stateId);
    segmentationActions.setStateSelectedId(stateIdSelected);
  }

  function onSelectedMunicipality({ municipality, clickedByMap }: OnSelectMunicipalityProps) {

    if (clickedByMap) {
      segmentationActions.setWasClickedByMap(true);
      segmentationActions.setMunicipalitySelected(municipality);
    }

    const municipalityIdSelected = getMunicipalitySelectedId(municipality);
    segmentationActions.setMunicipalitySelectedId(municipalityIdSelected);

  }

  function onReset() {
    segmentationActions.setStateSelected(undefined);
    segmentationActions.setStateSelectedId(undefined);
    segmentationActions.setStateClicked(undefined);
    segmentationActions.setMunicipalitySelectedId(undefined);
    segmentationActions.setMunicipalitySelected(undefined);
  }

  function resetSearch() {
    segmentationActions.setSearch("");
    segmentationActions.resetStatesAlloweds();

    if (searchRef.current) {
      searchRef.current.focus();
      searchRef.current.value = "";
    }
  }

  function setIconOnSearch() {
    return searchRef.current && searchRef.current.value.length === 0
      ? <GL_SEARCH fill={GL_COLORS.FONT_COLOR_DARK_GREY} />
      : <ButtonCleanSearch onClick={() => resetSearch()}>
        <GL_DELETE fill={GL_COLORS.FONT_COLOR_DARK_GREY} />
      </ButtonCleanSearch>;
  }

  const handleSearch = useDebounceCallback((value: string) => {
    segmentationActions.resetStatesAlloweds();
    segmentationActions.setSearch(value);
  }, 0);

  useEffect(() => {
    if (searchRef.current && createNotificationState.modalCreateNotificationIsOpen) {
      setTimeout(() => searchRef.current?.focus(), 400);
    }
  }, [createNotificationState.modalCreateNotificationIsOpen])

  return {
    onReset,
    onSelectState,
    onSelectedMunicipality,
    searchRef,
    setIconOnSearch,
    handleSearch
  };
}
