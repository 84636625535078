import { useAuth } from "@group-link-one/gl-auth";
import {
  GL_COLORS,
  GL_USER,
  GLButton,
  GLTextField,
  GLTips,
  GLTypography,
  useI18n,
} from "@group-link-one/grouplink-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import {
  Column,
  Field,
  FieldContainer,
  Grid,
  LeftColumn,
  MobileFriendlyField,
  RightColumn,
  Section,
} from "../styled";
import { useMyAccount } from "../useMyAccount";

export const MyAccountForm = () => {
  const { t } = useI18n();
  const { handleUpdateAccountInformation, myAccountFormSchema } = useMyAccount();
  const { user } = useAuth()

  const { handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    resolver: yupResolver(myAccountFormSchema),
    defaultValues: {
      name: user?.name,
      email: user?.email,
      last_name: user?.last_name,
      phone: user?.phone,
      job_title: user?.job_title,
      organization: user?.org.name
    }
  })

  const { email, last_name, name, phone, job_title, organization } = getValues()

  return (
    <Grid>
      <LeftColumn onSubmit={handleSubmit(handleUpdateAccountInformation)}>
        <Section>
          <GLTypography
            text={t("myAccount.formTitle")}
            as="span"
            fontSize={4}
            weight={600}
            color="DISABLED_FONT_COLOR"
            style={{
              textTransform: "uppercase",
            }}
          />

          <FieldContainer>
            <Column>
              <MobileFriendlyField>
                <GLTextField
                  title={t("myAccount.firstName")}
                  inputSize="secondary"
                  defaultValue={name}
                  type="text"
                  onChange={(e) => setValue("name", e.target.value)}
                  failure={!!errors.name?.message}
                  failureMessage={errors.name?.message}
                />

                <GLTextField
                  title={t("myAccount.lastName")}
                  defaultValue={last_name}
                  inputSize="secondary"
                  type="text"
                  failure={!!errors.last_name?.message}
                  failureMessage={errors.last_name?.message}
                  onChange={(e) =>
                    setValue("last_name", e.target.value)
                  }
                />
              </MobileFriendlyField>

              <Field>
                <GLTextField
                  title={t("myAccount.email")}
                  value={email}
                  inputSize="secondary"
                  failure={!!errors.email?.message}
                  failureMessage={errors.email?.message}
                  disabled={true}
                />

                <GLTextField
                  title={t("myAccount.phone")}
                  defaultValue={phone}
                  inputSize="secondary"
                  failure={!!errors.phone?.message}
                  failureMessage={errors.phone?.message}
                  onChange={(e) => setValue("phone", e.target.value)}
                  type="tel"
                />
              </Field>
            </Column>
          </FieldContainer>
        </Section>

        <Section>
          <GLTypography
            text={t("myAccount.organizationTitle")}
            as="span"
            weight={600}
            fontSize={4}
            color="DISABLED_FONT_COLOR"
            style={{
              textTransform: "uppercase",
            }}
          />

          <FieldContainer>
            <Column>
              <Field>
                <GLTextField
                  title={t("myAccount.jobTitle")}
                  value={job_title}
                  failure={!!errors.job_title?.message}
                  failureMessage={errors.job_title?.message}
                  inputSize="secondary"
                  disabled={true}
                />
                <GLTextField
                  title={t("myAccount.company")}
                  defaultValue={organization}
                  failure={!!errors.organization?.message}
                  failureMessage={errors.organization?.message}
                  inputSize="secondary"
                  disabled={true}
                />
              </Field>
            </Column>

            <Column>
              <Field>
                <span></span>
                <GLButton
                  className="button__update__profile"
                  text={t("myAccount.buttonForm")}
                  variant="fill"
                  border={true}
                  align="center"
                  size="secondary"
                  weight={600}
                  type="submit"
                  onClick={() => {
                    // handleUpdateAccountInformation
                  }}
                />
              </Field>
            </Column>
          </FieldContainer>
        </Section>
      </LeftColumn>

      <RightColumn>
        <GLTips icon={<GL_USER fill={GL_COLORS.FONT_COLOR} />} />
      </RightColumn>
    </Grid>
  );
};
