import { GLButton } from "@group-link-one/grouplink-components";
import { GLTextField } from "@group-link-one/grouplink-components";
import { GL_COLORS } from "@group-link-one/grouplink-components";
import { Colors } from "@group-link-one/grouplink-components";

import QRCode from "../../../../../images/qr-code.png";
import { Box, ButtonLink, StyledForm, Typography } from "./Style";

export const EnableTwoFactorForm = () => {
  return (
    <StyledForm /*onSubmit={handleSubmit(sendForm)}*/>
      <Typography fontSize={25} fontWeight='bold'>
        Enable two-factor authentication
      </Typography>

      <Typography fontSize={16}>
        Setting up 2FA adds an extra layer of security to verify and protect
        your identity. Simply follow the steps provided below to easily
        configure and enable this feature:
      </Typography>

      <Box>
        <Typography
          fontSize={16}
          fontWeight='bold'
          color={GL_COLORS.ACCENT_COLOR}
        >
          Step 1: Download an authenticator app
        </Typography>

        <Typography fontSize={16} fontWeight='normal' color={GL_COLORS.FONT_COLOR}>
          Download and install a mobile app like{" "}
          <b style={{ color: `${Colors.ULTRADARK_GREY}`, fontWeight: "bold" }}>
            Google Authenticator
          </b>{" "}
          or{" "}
          <b style={{ color: `${Colors.ULTRADARK_GREY}`, fontWeight: "bold" }}>
            2FAS
          </b>{" "}
          to generate verification codes.
        </Typography>
      </Box>

      <Box>
        <Typography
          fontSize={16}
          fontWeight='bold'
          color={GL_COLORS.ACCENT_COLOR}
        >
          Step 2: Scan QR code
        </Typography>

        <Typography fontSize={16} fontWeight='normal' color={GL_COLORS.FONT_COLOR}>
          Open the authenticator app and scan the image below:
        </Typography>

        <img
          src={QRCode}
          style={{
            alignSelf: "center",
            width: "100px",
            height: "100px",
          }}
          alt='QR Code'
        />
      </Box>

      <Box>
        <Typography
          fontSize={16}
          fontWeight='bold'
          color={GL_COLORS.ACCENT_COLOR}
        >
          Step 3: Verify your code
        </Typography>

        <Typography fontSize={16} fontWeight='normal' color={GL_COLORS.FONT_COLOR}>
          Enter the 6-digit verification code generated
        </Typography>

        <GLTextField
          inputSize='secondary'
          title=''
          placeholder='Type your code'
        // failure={!!errors.email?.message}
        // failureMessage={errors.email?.message}
        // onChange={(e) => setValue("email", e.target.value)}
        />

        <GLButton
          align='center'
          text='Verify code'
          variant='fill'
          type='submit'
        //   disabled={disabled}
        />
        <Typography
          fontSize={13}
          style={{
            alignSelf: "center",
          }}
        >
          <ButtonLink to="/login" type='button' /*onClick={redirectToLogin}*/>
            Get back to signin
          </ButtonLink>
        </Typography>
      </Box>
    </StyledForm>
  );
};
