import {
  GL_SMARTPHONE,
  GLDetailedModal,
  GLEmptyList,
  GLList,
  useI18n,
} from "@group-link-one/grouplink-components";

import { PageAnimatedContainer } from "../../../../utils/pageAnimated";
import { useApp } from "../useApp";
import { AppCards } from "./appCards";
import { ModalAppContent } from "./modalAppContent";

interface IAppContent {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const Content = ({ open, setOpen }: IAppContent) => {
  const { apps, count, isLoading } = useApp();
  const { t } = useI18n();

  const tab = [
    {
      name: t("overview.apps.title"),
      count,
      active: true,
      onClick: () => { },
    },
  ];

  return (
    <PageAnimatedContainer>
      {apps?.length === 0 ? (
        <div
          className="no__content"
          style={{
            width: "100%",
            height: "65vh",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <GLEmptyList
            hasBorder
            description="No Mobile App added"
            icon={<GL_SMARTPHONE size={40} fill="#888E9E" />}
            title="Click here to add your first one"
            onClick={() => setOpen(true)}
          />
        </div>
      ) : (
        <GLList
          tabActive="Apps"
          tabs={tab}
          content={<AppCards apps={apps} />}
          isLoading={isLoading}
        />
      )}
      <GLDetailedModal
        open={open}
        setIs={setOpen}
        title={t("overview.apps.modal.modalDetailed.title")}
        justifyContent="flex-start"
        key={"app-detailed-modal"}
        width="100%"
        content={<ModalAppContent setOpen={setOpen} />}
      />
    </PageAnimatedContainer>
  );
};
