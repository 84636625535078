import {
  GLHeaderNavListItemProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useMemo } from "react";

export enum SettingsRoutes {
  DEFAULT = "/settings",
  OVERVIEW = "/settings/overview",
  MY_ACCOUNT = "/settings/my-account",
  LOGIN_SECURITY = "/settings/login-security",
  USERS = "/settings/users",
  ORGANIZATIONS = "/settings/organizations",
  APPS = "/settings/apps",
}

export function useSettingsLayout() {
  const { t } = useI18n();

  const headerNavList: GLHeaderNavListItemProps[] = useMemo(() => {
    return [
      {
        id: 1,
        title: t("pages.deviceOverview.overview"),
        path: SettingsRoutes.OVERVIEW,
      },
      {
        id: 2,
        title: t("pages.settings.myAccount"),
        path: SettingsRoutes.MY_ACCOUNT,
      },
      {
        id: 3,
        title: t("pages.settings.loginSecurity"),
        path: SettingsRoutes.LOGIN_SECURITY,
      },
      // {
      //   id: 4,
      //   title: t("users.subtitle"),
      //   path: SettingsRoutes.USERS,
      // },
      // {
      //   id: 5,
      //   title: t("organizations.title"),
      //   path: SettingsRoutes.ORGANIZATIONS,
      // },
      {
        id: 6,
        title: t("pages.settings.apps"),
        path: SettingsRoutes.APPS,
      },
    ];
  }, []);

  const headerNavItemActive = headerNavList.find(
    (navItem) => navItem.path === window.location.pathname
  );

  return {
    headerNavList,
    headerNavItemActive,
  };
}
