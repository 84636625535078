import * as yup from "yup";

export const useTwoFactorService = () => {
  const sendForm = ({ passcode }: any) => {
    console.log(passcode);
  };

  const twoFactorSchema = yup.object().shape({
    passcode: yup.string().required("Code is required").min(6).max(6),
  });

  return {
    sendForm,
    twoFactorSchema,
  };
};
