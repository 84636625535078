import { GLButton, useI18n } from "@group-link-one/grouplink-components";
import { GLTextField } from "@group-link-one/grouplink-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { IForgotData } from "../types/type";
import { Box, ButtonLink, StyledForgotForm, Typography } from "./Style";

interface IForgotForm {
  sendForm: (data: IForgotData) => void;
  redirectToLogin: () => void;
  schema: yup.ObjectSchema<IForgotData>;
  disabled: boolean;
}

export const ForgotForm = ({
  sendForm,
  redirectToLogin,
  schema,
  disabled,
}: IForgotForm) => {
  const { t } = useI18n();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <StyledForgotForm onSubmit={handleSubmit(sendForm)}>
      <Typography fontSize={25} fontWeight="bold">
        {t("forgot.title")}
      </Typography>

      <Typography fontSize={16}>
        {t("forgot.description")}
      </Typography>

      <Box>
        <GLTextField
          inputSize="secondary"
          title={t("forgot.input.label")}
          placeholder={t("forgot.input.placeholder")}
          failure={!!errors.email?.message}
          failureMessage={errors.email?.message}
          onChange={(e: { target: { value: string } }) =>
            setValue("email", e.target.value)
          }
        />
      </Box>

      <Box>
        <GLButton
          align="center"
          text={t("forgot.button")}
          variant="fill"
          type="submit"
          disabled={disabled}
        />
        <ButtonLink type="button" onClick={redirectToLogin}>
          {t("forgot.link")}
        </ButtonLink>
      </Box>
    </StyledForgotForm>
  );
};
