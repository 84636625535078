import { useAuth } from "@group-link-one/gl-auth";

import { IApp, IAppVault } from "../../Pages/Settings/Apps/types/app";
import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useAppService = () => {
  const { accessToken } = useAuth();
  const { http: axiosInstance } = useAxiosHTTPRequest();
  const headers = { Authorization: accessToken };

  const createApp = async (data: IApp) => {
    return axiosInstance.post("/apps", data, {
      headers,
    });
  };

  const getApps = async (): Promise<IApp[]> => {
    return axiosInstance
      .get("/apps?ipp=2000", {
        headers,
      })
      .then((res) => res.data.rows);
  };

  const countApps = async (): Promise<number> => {
    return axiosInstance
      .get("/apps/count", {
        headers,
      })
      .then((res) => res.data.count);
  };

  const updateApps = async (appId: number, data: Partial<IApp>) => {
    return axiosInstance.patch(`/apps/${appId}`, data, {
      headers,
    });
  };

  const deleteApps = async (appId: number): Promise<void> => {
    return axiosInstance.delete(`/apps/${appId}`, {
      headers,
    });
  };

  const appsVault = async (vaultData: IAppVault) => {
    return axiosInstance.patch(
      "/organization-vault/message-center",
      vaultData,
      {
        headers,
      },
    );
  };

  return {
    createApp,
    getApps,
    countApps,
    updateApps,
    deleteApps,
    appsVault,
  };
};
