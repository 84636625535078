/* eslint-disable */

import { useEffect, useState } from "react";
import { useLoginSecurityServices } from "../../../Services/loginSecurityService/useLoginSecurityService";
import * as yup from "yup";
import { useAuthServices } from "../../../Services/authService/useAuthServices";
import { ILoginSecurityData } from "./types/loginSecurity.types";
import { useQuery } from "@tanstack/react-query";
import { useI18n, useToast } from "@group-link-one/grouplink-components";
import { useAuth } from "@group-link-one/gl-auth";

export const useLoginSecurity = () => {
  const [token, setToken] = useState("")
  const { logOut } = useAuthServices();
  const { t } = useI18n();
  const { user } = useAuth()
  const { updatePasswordLogged, getUserHistory, sendCode } = useLoginSecurityServices();
  const { addToast } = useToast()

  const generateCode = async () => {
    const response = await sendCode({ email: user!.email })

    setToken(response.data)

    if (response.data) {
      addToast({
        title: "Email enviado",
        message: "Verifique sua caixa de entrada",
        type: "success"
      })
    }

    return response
  }

  const updatePassword = async ({ password, code }: ILoginSecurityData) => {
    return updatePasswordLogged({
      token,
      code,
      password: password,
    }).then((res) => {
      if (res.data === 200) {
        addToast({
          title: "Senha alterada",
          message: "Senha alterada com sucesso",
          type: "success"
        })
      }
    }).catch((err) => {
      console.log(err)
      addToast({
        title: t("toast.error.title"),
        message: t("toast.error.message"),
        type: "error",
      });
    });
  };

  const { data: sessions, isLoading } = useQuery({
    queryKey: ["get-user-history"],
    queryFn: () => getUserHistory().then((res) => res.data.rows),
  });

  const loginSecuritySchema = yup.object().shape({
    code: yup.string().required("Code is required field").min(6, "Code must be longer than or equal to 6 characters"),
    password: yup
      .string()
      .required(t("loginAndSecurity.form.errors.password"))
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t("loginAndSecurity.form.message")
      ),
    confirm_password: yup
      .string()
      .required(t("loginAndSecurity.form.errors.confirm_password"))
      .oneOf([yup.ref("password")], t("loginAndSecurity.form.errors.match")),
  });

  const logOutDevice = async (id: string) => {
    await logOut({ session_id: id });
  };

  return {
    generateCode,
    updatePassword,
    logOutDevice,
    sessions,
    isLoading,
    loginSecuritySchema,
  };
};
