import { useTwoFactorService } from "../../useTwoFactor";
import { VerifyCodeForm } from "./VerifyCodeForm";

export const VerifyCode = () => {
  const { twoFactorSchema, sendForm } = useTwoFactorService();

  return (
    <VerifyCodeForm
      sendForm={sendForm}
      redirect={() => { }}
      schema={twoFactorSchema}
    />
  );
};
