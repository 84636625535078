import { useI18n } from "@group-link-one/grouplink-components";
import { useToast } from "@group-link-one/grouplink-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useForgotPasswordService } from "../../../Services/forgotPasswordService/useForgotServices";
import { IForgotData } from "./types/type";

export const useForgot = () => {
  const { t } = useI18n();
  const { sendResetPasswordLink } = useForgotPasswordService();
  const { addToast } = useToast();
  const [disabled] = useState(false);
  const navigate = useNavigate();

  const sendForm = async ({ email }: IForgotData) => {
    await sendResetPasswordLink(email);

    addToast({
      message: t("forgot.toast.title"),
      title: t("forgot.toast.message"),
      type: "success",
    });
  };

  const redirectToLogin = () => {
    navigate("/");
  };

  const forgotSchema = yup.object().shape({
    email: yup.string().email().required(t("login.form.errors.email")),
  });

  useEffect(() => {
    setInterval(() => { }, 60000);
  }, []);

  return {
    sendForm,
    redirectToLogin,
    forgotSchema,
    disabled,
  };
};
