import { GL_COLORS } from "@group-link-one/grouplink-components";
import { Link } from "react-router-dom";
import styled from "styled-components";

enum FontWeight {
  thin = "100",
  extraLight = "200",
  light = "300",
  normal = "400",
  medium = "500",
  semiBold = "600",
  bold = "700",
  extraBold = "800",
  black = "900",
}

interface ITypography {
  fontSize: number;
  fontWeight?: keyof typeof FontWeight;
  color?: string;
}

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  min-width: 335px;
  gap: 30px;
  height: 70vh;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }

  @media screen and (min-width: 1024px) {
    width: 480px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BoxSpanned = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 12px;
    width: 300px;
    align-self: center;
    text-align: center;
  }
`;

export const ButtonLink = styled(Link)`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: ${GL_COLORS.ACCENT_COLOR};
`;

export const Typography = styled.p<ITypography>`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => {
    return fontSize + "px";
  }};
  color: ${(props) => props.color};
`;
