import { GL_COLORS, GL_PLUS, GLButton, GLButtonIcon, GLExtendedTextField, useI18n } from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

import { useCreateNotificationStore } from "../../ModalCreateNotification/store/createNotification";
import { useNotificationsContext } from "../Content/useNotificationsContent";
import { NotificationsRightContentContainer } from "./NotificationsRightContentStyle";

export function NotificationsRightContent() {
  const { t } = useI18n()
  const { actions } = useCreateNotificationStore();
  const {
    handleSearch
  } = useNotificationsContext();

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <NotificationsRightContentContainer>
      <GLExtendedTextField onChange={handleSearch} />
      {isMobile ? (
        <GLButtonIcon
          variant="fill"
          icon={<GL_PLUS />}
          onClick={() => actions.setModalCreateNotificationIsOpen(true)}

        />
      ) : (
        <GLButton
          variant="fill"
          align="center"
          weight={600}
          text={t("messageCenter.notifications.headerButton")}
          onClick={() => actions.setModalCreateNotificationIsOpen(true)}
          style={{
            width: "fit-content",
            padding: "15px 30px",
            color: GL_COLORS.FONT_COLOR_VARIANT,
          }}
        />

      )}
    </NotificationsRightContentContainer>
  );
}
