import { useI18n } from "@group-link-one/grouplink-components";
import * as yup from "yup";

interface useAppSchemaProps {
  androidSelected: boolean;
  iosSelected: boolean;
}

export const useAppSchema = ({
  androidSelected,
  iosSelected,
}: useAppSchemaProps) => {
  const { t } = useI18n();

  const verifyJsonFile = async (value: File | any) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = async (event: any) => {
        const data = JSON.parse(event.target.result);

        const keys = Object.keys(data);

        const haveKeys =
          keys.includes("private_key") && keys.includes("private_key_id");

        if (!haveKeys || !value || !value.path.endsWith(".json")) {
          reject("");
        } else {
          resolve(true);
        }
      };

      reader.readAsText(value);
    });
  };

  const verifyP8File = async (value: File | any) => {
    if (!value || !value.path.endsWith(".p8")) return false;
    return true;
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        t("overview.apps.modal.modalDetailed.form.errors.android.appName"),
      ),
    android: androidSelected
      ? yup.object().shape({
        pkg_name_android: yup
          .string()
          .required(
            t(
              "overview.apps.modal.modalDetailed.form.errors.android.packageName",
            ),
          ),
        sender_id: yup
          .string()
          .min(
            12,
            t(
              "overview.apps.modal.modalDetailed.form.errors.android.firebaseSenderIdMin",
            ),
          )
          .required(
            t(
              "overview.apps.modal.modalDetailed.form.errors.android.firebaseSenderId",
            ),
          ),
        credentials_json: yup
          .mixed()
          .test(
            "fileFormat",
            t(
              "overview.apps.modal.modalDetailed.form.errors.android.androidJsonFile",
            ),
            async (value: File | any) => {
              try {
                await verifyJsonFile(value);
                return true;
              } catch (err) {
                return false;
              }
            },
          ),
        android: yup.boolean().default(true),
      })
      : yup.object().shape({
        pkg_name_android: yup.string().nullable(),
        sender_id: yup.string().nullable(),
        credentials_json: yup.mixed().nullable(),
        android: yup.boolean().default(false),
      }),
    ios: iosSelected
      ? yup.object().shape({
        pkg_name_ios: yup
          .string()
          .required(
            t(
              "overview.apps.modal.modalDetailed.form.errors.ios.pkg_name_ios",
            ),
          ),
        key_id: yup
          .string()
          .min(
            10,
            t("overview.apps.modal.modalDetailed.form.errors.ios.keyIdMin"),
          )
          .required(
            t("overview.apps.modal.modalDetailed.form.errors.ios.keyId"),
          ),
        team_id: yup
          .string()
          .min(
            10,
            t("overview.apps.modal.modalDetailed.form.errors.ios.teamIdmin"),
          )
          .required(
            t("overview.apps.modal.modalDetailed.form.errors.ios.teamId"),
          ),
        ios: yup.boolean().default(true),
        p8_key_file: yup
          .mixed()
          .test(
            "fileFormat",
            t("overview.apps.modal.modalDetailed.form.errors.ios.p8KeyFile"),
            async (value: File | any) => {
              try {
                await verifyP8File(value);
                return true;
              } catch (err) {
                return false;
              }
            },
          ),
      })
      : yup.object().shape({
        pkg_name_ios: yup.string().nullable(),
        key_id: yup.string().nullable(),
        team_id: yup.string().nullable(),
        ios: yup.boolean().default(false),
        p8_key_file: yup.mixed().nullable(),
      }),
  });

  return {
    schema,
  };
};
