import { GLBrazilMap, GLTextField, GLTypography, GLZoomPanPinch, useI18n } from "@group-link-one/grouplink-components";
import { memo } from "react";

// import { AnimatePresence, motion } from "framer-motion";
// import { useMediaQuery } from "usehooks-ts";
import { useSegmentationStore } from "../store/segmentation";
import { ListMunicipalities } from "./components/ListMunicipalities/ListMunicipalities";
import { SegmentationContainer } from "./segmentationStyle";
import { useSegmentation } from "./useSegmentation";

function SegmentationContent() {
  const { t } = useI18n()
  const { state: segmentationState } = useSegmentationStore();
  const { onSelectState, onReset, onSelectedMunicipality, searchRef, setIconOnSearch, handleSearch } = useSegmentation();
  // const isMobile = useMediaQuery("(max-width: 1024px)");


  return (
    <SegmentationContainer>
      <GLTypography
        text={t("messageCenter.notifications.segmentation.map.title")}
        fontSize={4}
        color="FONT_COLOR"
        weight={400}
      />

      <div className="segmentation-content">
        <div className="segmentation-location">

          <div className="location-header">
            <GLTypography
              text={t("messageCenter.notifications.segmentation.map.audienceLocation")}
              fontSize={5}
              color="FONT_COLOR"
              weight={600}
            />

            <GLTypography
              text={t("messageCenter.notifications.segmentation.map.followingLocations")}
              fontSize={4}
              color="FONT_COLOR"
              weight={400}
            />

          </div>

          <div className="location-filters">
            <GLTextField
              ref={searchRef}
              title=""
              value={segmentationState.search}
              placeholder={t("messageCenter.notifications.segmentation.map.searchMunicipality")}
              icon={setIconOnSearch()}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          <div className="location-list">
            <ListMunicipalities />
          </div>
        </div>

        <div className="segmentation-map">
          <GLZoomPanPinch
            onReset={onReset}
            style={{ borderWidth: 0 }}
            showButtonBack={!!segmentationState.stateSelected}
          >
            <GLBrazilMap
              stateSelected={segmentationState.stateSelected}
              statesAlloweds={segmentationState.statesAlloweds}
              zoomOnSelect={3}
              municipalitiesAlloweds={segmentationState.municipalitiesAllowed}
              municipalitiesSelecteds={segmentationState.municipalitiesSelected}
              wasClickedByMap={segmentationState.wasClickedByMap}
              onSelectMunicipality={onSelectedMunicipality}
              onSelectState={onSelectState}
              statesIsLoading={!segmentationState.statesIsLoading}
            />
          </GLZoomPanPinch>
        </div>
      </div>

    </SegmentationContainer>
  );
}

export const Segmentation = memo(SegmentationContent);
