import {
  GLMenuMobileProvider,
  GLModalLogoutProvider,
  GLToastProvider,
  I18n,
} from "@group-link-one/grouplink-components";

import { useCallbackToModalLogout } from "./hooks/useCallbackToModalLogout";
import { Routes } from "./Routes/index.routes";

function App() {
  const { callbackFnToModalLogout } = useCallbackToModalLogout();

  return (
    <GLModalLogoutProvider callbackFnToModalLogout={callbackFnToModalLogout}>
      <GLMenuMobileProvider>
        <GLToastProvider>
          <I18n>
            <Routes />
          </I18n>
          {/* <ReactQueryDevtools /> */}
        </GLToastProvider>
      </GLMenuMobileProvider>
    </GLModalLogoutProvider>
  );
}

export default App;
