import { Colors, UNIVERSAL_PROPERTIES } from "@group-link-one/grouplink-components";
import styled from "styled-components";

import { PageAnimatedContainer } from "../../../../utils/pageAnimated";

export const Container = styled(PageAnimatedContainer)`
  display: grid;
  gap: 20px;
  grid-template-columns: 0.5fr 0.3fr;

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    gap: 20px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 20px;

      > p:nth-child(1) {
        text-transform: uppercase;
        color: ${Colors.GREY};
        font-size: ${UNIVERSAL_PROPERTIES.FONT_SIZE_SECONDARY};
        font-weight: 600;
      }
    }
  }

  @media screen and (min-width: 1199px) {
    flex-direction: row;
    gap: 130px;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .custom__paragraph {
    text-transform: uppercase;
    color: ${Colors.GREY};
    font-size: ${UNIVERSAL_PROPERTIES.FONT_SIZE_SECONDARY};
    font-weight: 600;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
    .inputs__code, .inputs__passwords, .inputs__warning {
      display: flex;
      flex-direction: column;
      gap: 10px
    }

    @media screen and (min-width: 1199px) {
      .inputs__code {
        flex-direction: row;
        align-items: center;
        
        button {
          align-self: flex-end;
        }
      }  
      
      .inputs__passwords {
        flex-direction: row;
      }

      .inputs__warning {
        width: 100%;
      }


      .button {

      }
    }
  }

`;
