import { GLButton } from "@group-link-one/grouplink-components";
import { GLTextField } from "@group-link-one/grouplink-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { IVerifyCode } from "../../types/verifyCode.types";
// import { GLTextField } from "../../../../../Components/GLTextField/GLTextField";
import { Box, ButtonLink, StyledForm, Typography } from "./Style";

export const VerifyCodeForm = ({ sendForm, redirect, schema }: IVerifyCode) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <StyledForm onSubmit={handleSubmit(sendForm)}>
      <Typography fontSize={25} fontWeight="bold">
        Verification code
      </Typography>

      <Typography fontSize={16}>
        Enter or paste the 6-digit code generated by your authentication app to
        confirm your action.
      </Typography>

      <Box>
        <GLTextField
          inputSize="secondary"
          title=""
          placeholder="Type your code"
          failure={!!errors.passcode?.message}
          failureMessage={errors.passcode?.message}
          onChange={(e) => setValue("passcode", e.target.value)}
        />
      </Box>

      <Box>
        <GLButton
          align="center"
          text="Verify code"
          variant="fill"
          type="submit"
        // disabled={disabled}
        />
        <Typography
          fontSize={13}
          style={{
            alignSelf: "center",
          }}
        >
          Can't find your code?
          <ButtonLink type="button" onClick={redirect}>
            Use a backup
          </ButtonLink>
        </Typography>
      </Box>
    </StyledForm>
  );
};
