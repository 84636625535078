import { Navigate, Route, Routes } from "react-router-dom";

import { OnboardLayout } from "../layouts/OnboardLayout/OnboardLayout";
import { Forgot } from "../Pages/Onboard/Forgot/forgot";
import { Login } from "../Pages/Onboard/Login/login";
import { EnableTwoFactor } from "../Pages/Onboard/TwoFactorAuth/Forms/EnableTwoFactor/enableTwoFactor";
import { VerifyCode } from "../Pages/Onboard/TwoFactorAuth/Forms/VericationCode/verificationCode";


export const AuthRoutes = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={<OnboardLayout />}
      >
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Navigate to='/login' />} />
        <Route path='/forgot-password' element={<Forgot />} />

        <Route path='/enable-two-factor' element={<EnableTwoFactor />} />
        <Route path='/verification-code' element={<VerifyCode />} />
        <Route path='*' element={<Navigate to='/login' />} />
      </Route>

    </Routes>
  );
};
