import { GLMainContent, useI18n } from "@group-link-one/grouplink-components";

import { OverviewContent } from "./Content/overviewContent";

export const Overview = () => {
  const { t } = useI18n()
  return (
    <GLMainContent
      content={<OverviewContent />}
      subtitle={t("myAccount.title")}
      title={t("overview.subtitle")}
      rightContent={<></>}
    />
  );
};
