import { createContext, useState } from "react";

export const AppContext = createContext({} as any);

export const AppProvider = ({ children }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [appData, setAppData] = useState({});

  return (
    <AppContext.Provider
      value={{
        openModal,
        appData,
        setOpenModal,
        setAppData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
