import { useAuth } from "@group-link-one/gl-auth";
import {
  GL_COLORS,
  GL_MONITOR,
  GL_PADLOCK,
  GLButton,
  GLSessionCard,
  GLSkeletonCard,
  GLTextField,
  GLTipsCard,
  GLWarningCard,
  useI18n,
} from "@group-link-one/grouplink-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { useLoginSecurity } from "../useLoginSecurity";
import { Container, StyledForm } from "./Style";

export const Content = () => {
  const [isDeactivating, setIsDeactivating] = useState(false);
  const { user } = useAuth();
  const { t } = useI18n();
  const {
    generateCode,
    updatePassword,
    logOutDevice,
    sessions,
    loginSecuritySchema,
    isLoading,
  } = useLoginSecurity();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSecuritySchema),
  });


  async function sendCode() {
    setIsDeactivating(true);
    await generateCode();
    setIsDeactivating(false);
  }

  return (
    <Container>
      <div>
        <StyledForm onSubmit={handleSubmit(updatePassword)}>
          <div>
            <p className="custom__paragraph">{t("loginAndSecurity.form.title")}</p>

            <div className="inputs">
              <div className="inputs__code">
                <GLTextField
                  title={t("code")}
                  type="text"
                  onChange={(e) => setValue("code", e.target.value)}
                  failure={!!errors.code?.message}
                  failureMessage={errors.code?.message}
                />
                <GLButton
                  variant="fill"
                  align="center"
                  text={t("Send code")}
                  size={"secondary"}
                  type="button"
                  weight={600}
                  isLoading={isDeactivating}
                  disabled={isDeactivating}
                  onClick={sendCode}
                  style={{
                    marginBottom: errors.code?.message ? "27px" : "0px"
                  }}
                />
              </div>
              <div className="inputs__passwords">
                <div className="inputs__warning">
                  <GLTextField
                    title={t("loginAndSecurity.form.newPassword")}
                    type="password"
                    onChange={(e) => setValue("password", e.target.value)}
                    failure={!!errors.password?.message}
                    failureMessage={errors.password?.message}
                  />
                  <div>
                    <GLWarningCard text={t("loginAndSecurity.form.message")} />
                  </div>
                </div>
                <GLTextField
                  title={t("loginAndSecurity.form.confirmPassword")}
                  type="password"
                  onChange={(e) => setValue("confirm_password", e.target.value)}
                  failure={!!errors.confirm_password?.message}
                  failureMessage={errors.confirm_password?.message}
                />
              </div>
            </div>
          </div>

          <div className="button">
            <GLButton
              variant="fill"
              align="center"
              text={t("loginAndSecurity.form.buttonForm")}
              size={"secondary"}
              type="submit"
              weight={600}
            />
          </div>
        </StyledForm>

        <div>
          <p>{t("loginAndSecurity.history.title")}</p>

          {isLoading
            ? [0, 1, 2].map((index) => <GLSkeletonCard key={index} />)
            : sessions?.map((session: any) => (
              <GLSessionCard
                key={session.id}
                browser={session.user_agent}
                os={session.user_agent}
                current={+session.id === user?.session_id}
                icon={
                  <GL_MONITOR
                    size={30}
                    fill={GL_COLORS.DISABLED_FONT_COLOR}
                  />
                }
                onClick={() => logOutDevice(session.id)}
                session={session.created_at}
              />
            ))}
        </div>
      </div>

      <div style={{
        maxHeight: "250px"
      }}>
        <GLTipsCard
          icon={<GL_PADLOCK size={30} fill={GL_COLORS.DISABLED_FONT_COLOR} />}
          title={t("loginAndSecurity.securityTips.title")}
          content={
            <div>
              <p>{t("loginAndSecurity.securityTips.textOne")}</p>

              <p>{t("loginAndSecurity.securityTips.textTwo")}</p>

              <p> {t("loginAndSecurity.securityTips.textThree")}</p>
            </div>
          }
        />
      </div>
    </Container>
  );
};
