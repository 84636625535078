import {
  GL_COLORS,
  UNIVERSAL_PROPERTIES,
} from "@group-link-one/grouplink-components";
import styled from "styled-components";

interface ITypography {
  fontSize: number;
  fontWeight?: string;
  color?: "blue" | "red";
}

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 380px;
  min-width: 335px;
  gap: 20px;
  @media screen and (min-width: 1024px) {
    width: 380px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BoxSpanned = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 12px;
    width: 300px;
    align-self: center;
    text-align: center;
  }
`;

export const ButtonLink = styled.button`
  border: none;
  background-color: transparent;
  align-self: flex-end;
  text-decoration: underline;
  color: ${GL_COLORS.ACCENT_COLOR};
  font-size: ${UNIVERSAL_PROPERTIES.FONT_SIZE_SECONDARY};
  padding: 10px 0px 30px 0px;
`;

export const Typography = styled.p<ITypography>`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => {
    return fontSize + "px";
  }};
`;
