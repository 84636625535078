import { useI18n } from "@group-link-one/grouplink-components";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { useRef } from "react";
import { useMediaQuery } from "usehooks-ts";

import { TabProps } from "../../../@types/TabProps";
import { useModalContent } from "./ModalContent/useModalContent";
import { useCreateNotificationStore } from "./store/createNotification";
import { useSelectTemplateStore } from "./store/selectTemplate";

export const useModalCreateNotification = () => {
  const { t } = useI18n()
  const { actions: createNotificationActions, state: createNotificationState } =
    useCreateNotificationStore();

  const { state: selectTemplateState, actions: selectTemplateActions } = useSelectTemplateStore();
  const { resetAll: resetAllFn } = useModalContent();

  const mediaIsMobile = useMediaQuery("(max-width: 1024px)");

  const DISTANCE_STEPER_FROM_TOP = mediaIsMobile ? 90 : 220;
  const refDetailedModal = useRef<HTMLDivElement>(null);

  const tabs: TabProps[] = [
    {
      id: 1,
      cacheKey: "modal-new-notification-all-templates",
      name: t("messageCenter.notifications.creationModal.allTemplates"),
      count: 10,
      active: true,
      onClick: () => { },
    }
  ]

  const lastNextPageToken = {
    "modal-new-notification-all-templates": {
      token: undefined,
      page: undefined,
    }
  }

  const { scrollY } = useScroll({
    container: refDetailedModal,
  });

  function resetAll() {
    resetAllFn();
  }

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (
      latest > DISTANCE_STEPER_FROM_TOP &&
      !createNotificationState.steperIsOnTop
    ) {
      createNotificationActions.setSteperIsOnTop(true);
    } else if (
      latest <= DISTANCE_STEPER_FROM_TOP &&
      createNotificationState.steperIsOnTop
    ) {
      createNotificationActions.setSteperIsOnTop(false);
    }
  });

  return {
    selectTemplateState,
    selectTemplateActions,
    refDetailedModal,
    createNotificationActions,
    createNotificationState,
    resetAll,
    tabs,
    lastNextPageToken,
  };
};
