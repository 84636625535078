import { useAuth } from "@group-link-one/gl-auth";
import {
  Colors,
  GL_COLORS,
  GL_KEY,
  GL_MONITOR,
  GL_SMARTPHONE,
  GLButton,
  GLNormalCard,
  GLOverviewProfileCard,
  UNIVERSAL_PROPERTIES,
  useI18n,
} from "@group-link-one/grouplink-components";
import { format } from "date-fns";
import { enUS, es, pt } from "date-fns/locale"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useApp } from "../../Apps/useApp";
import { DivCard, DivContent, StyledDiv } from "./Style";

export const OverviewContent = () => {
  const language = localStorage.getItem("i18nextLng");
  const [lang, setLang] = useState<any>();
  const { t } = useI18n()
  const { count } = useApp();
  const navigate = useNavigate();
  const { user } = useAuth();
  if (!user) return null;


  useEffect(() => {
    language === "en";
    if (language === "en-US" || language === "en") setLang(enUS);
    if (language === "pt-BR" || language === "pt") setLang(pt);
    if (language === "es-es" || language === "es") setLang(es);
  }, []);


  return (
    <StyledDiv>
      <GLOverviewProfileCard
        addresses={[]}
        email={user?.email ? user?.email : ""}
        name={user?.name ? user.name : ""}
        last_name={user?.last_name ? user.last_name : ""}
        organization={user?.org.name ? user.org.name : ""}
        job_title={user?.job_title ? user.job_title : ""}
        editProfileButton={() => navigate("/settings/my-account")}
      />

      <DivContent>
        <GLNormalCard
          border={`1px solid ${GL_COLORS.BORDER_COLOR}`}
          content={
            <DivCard>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <GL_MONITOR fill={Colors.ULTRADARK_GREY} size={30} />
                <p style={{ color: Colors.ULTRADARK_GREY, fontWeight: 600 }}>
                  {t("overview.session.title")}
                </p>

                <p
                  style={{
                    fontSize: `${UNIVERSAL_PROPERTIES.FONT_SIZE_PRIMARY_SM}`,
                  }}
                >
                  {format(new Date(), "MMMM d, yyyy - hh:mm", { locale: lang })}
                </p>
              </div>

              <GLButton
                style={{
                  fontWeight: "bold",
                  fontSize: `${UNIVERSAL_PROPERTIES.FONT_SIZE_PRIMARY_SM}`,
                  alignSelf: "flex-start",
                  color: `${Colors.ACCENT_COLOR}`,
                  alignItems: "end",
                }}
                text={t("overview.session.button")}
                variant='outline'
                align='left'
                onClick={() => navigate("/settings/login-security")}
              />
            </DivCard>
          }
          title=""
        />
        <GLNormalCard
          border={`1px solid ${GL_COLORS.BORDER_COLOR}`}
          content={
            <DivCard>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <GL_KEY fill={Colors.ULTRADARK_GREY} size={30} />
                <p style={{ color: Colors.ULTRADARK_GREY, fontWeight: 600 }}>
                  {t("overview.security.title")}
                </p>

                <p></p>
              </div>

              <GLButton
                style={{
                  fontWeight: "bold",
                  fontSize: `${UNIVERSAL_PROPERTIES.FONT_SIZE_PRIMARY_SM}`,
                  alignSelf: "flex-start",
                  color: `${Colors.ACCENT_COLOR}`,
                  alignItems: "end",
                }}
                text={t("overview.security.button")}
                variant='outline'
                align='left'
                onClick={() => navigate("/settings/login-security")}
              />
            </DivCard>
          }
          title=""
        />
        <GLNormalCard
          border={`1px solid ${GL_COLORS.BORDER_COLOR}`}
          content={
            <DivCard>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <GL_SMARTPHONE fill={Colors.ULTRADARK_GREY} size={30} />
                <p style={{ color: Colors.ULTRADARK_GREY, fontWeight: 600 }}>
                  {t("overview.apps.title")}
                </p>


                {

                  count && (

                    <p
                      style={{
                        fontSize: `${UNIVERSAL_PROPERTIES.FONT_SIZE_PRIMARY_SM}`,
                      }}
                    >
                      {t("overview.apps.totalApps")}: {" "}  {count}
                    </p>
                  )
                }

              </div>

              <GLButton
                style={{
                  fontWeight: "bold",
                  fontSize: `${UNIVERSAL_PROPERTIES.FONT_SIZE_PRIMARY_SM}`,
                  alignSelf: "flex-start",
                  color: `${Colors.ACCENT_COLOR}`,
                  alignItems: "end",
                }}
                text={t("overview.apps.button")}
                variant='outline'
                align='left'
                onClick={() => navigate("/settings/apps")}
              />
            </DivCard>
          }
          title=""
        />
      </DivContent>
    </StyledDiv>
  );
};
