import {
  GL_SEND_MESSAGE,
  GL_SETTINGS,
  GLAsideItemProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useNavigate } from "react-router-dom";

import { verifyIfPathIsActive } from "../../utils/verifyIfPathIsActive";
import { MessageCenterRoutes } from "../MessageCenterLayout/useMessageCenterLayout";

export enum MainRoutes {
  DEVICES = "/devices",
  MESSAGE_CENTER = "/message-center",
  SETTINGS = "/settings",
}

export function useMainLayout() {
  const { t } = useI18n();
  const navigate = useNavigate();

  const navListTop: GLAsideItemProps[] = [
    {
      id: 1,
      Icon: GL_SEND_MESSAGE,
      text: t("messageCenter.title"),
      active: verifyIfPathIsActive(MainRoutes.MESSAGE_CENTER, false),
      dropdownItems: [
        {
          id: 1,
          text: t("messageCenter.notifications.notifications"),
          onClick: () => navigate(MessageCenterRoutes.NOTIFICATIONS),
        },
      ],
      onClick: () => {
        // navigate(MainRoutes.MESSAGE_CENTER);
      },
    },
  ];

  const navListBottom: GLAsideItemProps[] = [
    {
      id: 4,
      Icon: GL_SETTINGS,
      text: t("pages.settings.title"),
      active: verifyIfPathIsActive(MainRoutes.SETTINGS, false),
      onClick: () => {
        navigate(MainRoutes.SETTINGS);
      },
    },
  ];

  return { navListTop, navListBottom };
}
