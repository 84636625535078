import { MyAccForm } from "../../Pages/Settings/MyAccount/types";
import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useMyAccountService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const updateMyAccount = async (data?: MyAccForm) => {
    return await httpPrivate.patch("/my-account", data);
  };

  return {
    updateMyAccount,
  };
};
