import { useI18n } from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

import { ListNotificationsResponse } from "../../../../../Services/messageCenterService/useListNotifications/useListNotifications.types";
import { useNotificationsStore } from "../../../Notifications/store/notifications-store";
import { useSelectTemplateStore } from "../../store/selectTemplate";

export const useSelectTemplateCards = () => {
  const { t } = useI18n()
  const { state: selectTemplateState, actions: selectTemplateActions } = useSelectTemplateStore();
  const { actions: notificationsActions } = useNotificationsStore();

  const isMobile = useMediaQuery("(max-width: 1024px)");
  const isToUseCardMobile = isMobile || selectTemplateState.typeList === "grid";

  const categoryMap = {
    1: {
      title: t("messageCenter.notifications.cards.classA"),
      description: t("messageCenter.notifications.cards.informative"),
    },
    2: {
      title: t("messageCenter.notifications.cards.classB"),
      description: t("messageCenter.notifications.cards.urgent"),
    },
    3: {
      title: "C-Class",
      description: t("messageCenter.notifications.cards.informative"),
    }
  }

  function openModalPreviewNotification() {
    selectTemplateActions.setModalPreviewNotificationIsOpen(true);
  }

  function onClickTemplateCard(template: ListNotificationsResponse) {
    if (template.title === selectTemplateState.templateSelectedId) {
      selectTemplateActions.setTemplateSelectedId("");
      notificationsActions.resetAll();
    } else {
      selectTemplateActions.setTemplateSelectedId(template.title);
      notificationsActions.setCategoryId(template.category_id);
      notificationsActions.setMessageId(template.message_id);

      notificationsActions.setPopupPreviewNotificationContent({
        title: template.title,
        message: template.body,
        categoryTitle: getCategoryById(template.category_id).title,
        categoryDescription: getCategoryById(template.category_id).description,
      });

    }
  }

  function getCategoryById(categoryId: number) {
    if (categoryId in categoryMap) {
      return categoryMap[categoryId as keyof typeof categoryMap];
    }

    return categoryMap[1];
  }

  return {
    selectTemplateState,
    selectTemplateActions,
    openModalPreviewNotification,
    isToUseCardMobile,
    getCategoryById,
    onClickTemplateCard,
    categoryMap
  }
}
