import { GL_COLORS } from "@group-link-one/grouplink-components";
import styled from "styled-components";

interface ITypography {
  fontSize: number;
  fontWeight?: string;
  color?: "blue" | "red";
}

export const StyledForgotForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 380px;
  min-width: 335px;
  gap: 30px;
  @media screen and (min-width: 1024px) {
    width: 380px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BoxSpanned = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 12px;
    width: 300px;
    align-self: center;
    text-align: center;
  }
`;

export const ButtonLink = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: ${GL_COLORS.ACCENT_COLOR};
`;

export const Typography = styled.p<ITypography>`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => {
    return fontSize + "px";
  }};
`;
