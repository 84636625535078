import { ICodeParams, ILoginSecurityData } from "../../Pages/Settings/LoginSecurity/types/loginSecurity.types";
import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useLoginSecurityServices = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const sendCode = async (data: ICodeParams) => {
    return await httpPrivate.post("/send-code", data)
  }

  const updatePasswordLogged = async (body: ILoginSecurityData) => {
    return await httpPrivate.patch("/reset-password-logged", body, {
      method: "PATCH",
    });
  };

  const getUserHistory = async () => {
    return await httpPrivate.get("/my-account/sessions?ipp=3");
  };

  return {
    sendCode,
    updatePasswordLogged,
    getUserHistory,
  };
};
