import { MunicipalityPerStateFormatted, StatesMap } from "@group-link-one/grouplink-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounceValue } from "usehooks-ts";

import { useListGeoAdmnistrativeAreas } from "../../../../../../Services/messageCenterService/useListGeoAdmnistrativeAreas/useListGeoAdmnistrativeAreas";
import { DivisionsRow } from "../../../../../../Services/messageCenterService/useListGeoAdmnistrativeAreas/useListGeoAdmnistrativeAreas.types";
import { formatStringToWithoutAccents } from "../../../../../../utils/formatStringToWithoutAccents";
import { useCreateNotificationStore } from "../../../store/createNotification";
import { useSegmentationStore } from "../../../store/segmentation";

const ONE_DAY = 1000 * 60 * 60 * 24;

export const useListMunicipalities = () => {
  const [municipalitiesPerState] =
    useState<MunicipalityPerStateFormatted | undefined>(undefined);

  const [municipalitiesLoaded, setMunicipalitiesLoaded] = useState<boolean[]>([false]);

  const { getStates, getDivisions } = useListGeoAdmnistrativeAreas();
  const queryClient = useQueryClient();

  const {
    state: segmentationState,
    actions: segmentationActions
  } = useSegmentationStore();

  const searchDebounced = useDebounceValue(segmentationState.search, 400)[0];

  const { modalCreateNotificationIsOpen } = useCreateNotificationStore(state => ({
    modalCreateNotificationIsOpen: state.state.modalCreateNotificationIsOpen
  }));

  function onOpenAccordionState(stateCode: string, stateId: number) {

    if (segmentationState.stateSelected === stateCode) {
      segmentationActions.setStateSelected(undefined);
      segmentationActions.setStateSelectedId(undefined);
      segmentationActions.setMunicipalitySelectedId(undefined);
      segmentationActions.setMunicipalitySelected(undefined);
      return;
    }

    segmentationActions.setStateSelected(stateCode as StatesMap);
    segmentationActions.setStateSelectedId(stateId);
  }

  function onClickInMunicipality(e: React.MouseEvent<HTMLDivElement, MouseEvent>, municipality: string) {
    e.stopPropagation();
    e.preventDefault();

    segmentationActions.setWasClickedByMap(false);

    if (segmentationState.municipalitiesSelected?.includes(municipality)) {
      segmentationActions.setMunicipalitySelected(undefined);
      segmentationActions.setMunicipalitySelectedId(undefined);
    } else {
      segmentationActions.setMunicipalitySelected(municipality);
    }

  }

  function accordionListScrollTo(index: number) {
    if (index < 0) return;

    const listContainer = document.querySelector(".accordion-list-municipalities");
    const offsetTop = index * 45;

    listContainer?.scrollTo({
      top: offsetTop,
      behavior: "smooth"
    });
  }

  function accordionStateListScrollTo() {
    const listStatesContainer = document.querySelector("[data-id='BR'] + div")
    if (!listStatesContainer) return;

    const accordionStatesRoot = listStatesContainer.querySelector(".gl-accordion-root") as HTMLElement;

    // const stateActive = listStatesContainer.querySelector(".gl-accordion-item-header.active")?.parentNode as HTMLElement;

    const stateActiveIndex = Array.from(accordionStatesRoot.children).
      findIndex((nodeState) => nodeState.querySelector(".gl-accordion-item-header.active"));

    if (stateActiveIndex === -1) return;

    listStatesContainer.scrollTo({
      top: stateActiveIndex * 59,
      behavior: "smooth"
    });
  }

  function scrollListMunicipalities() {
    const firstMunicipalitySelected = segmentationState.municipalitiesSelected?.[0];
    if (!firstMunicipalitySelected) return;

    const indexOfMunicipalitySelected = municipalitiesInStateActive?.findIndex(
      (municipality) => municipality.division_name === firstMunicipalitySelected
    )

    if (!indexOfMunicipalitySelected && indexOfMunicipalitySelected !== -1) return;

    accordionListScrollTo(indexOfMunicipalitySelected);
  }

  const getAllMunicipalitiesCached = useCallback(() => {

    const allMunicipalitiesCached =
      queryClient.getQueriesData({
        queryKey: ["municipalities-per-state-BRA"]
      })

    return allMunicipalitiesCached;

  }, [])

  const { data: statesBRA, isLoading: statesBRAisLoading } = useQuery({
    queryKey: ["all-states-BRA"],
    queryFn: async () => {
      const response = await getStates({
        country_code: "BRA"
      });

      return response;
    },
    staleTime: ONE_DAY
  })

  const statesIsLoaded = useMemo(() => {
    return municipalitiesLoaded.every((municipality) => municipality === true)
  }, [municipalitiesLoaded])

  const statesFiltereds = useMemo(() => {
    if (!statesIsLoaded) return [];

    const allMunicipalitiesCached = getAllMunicipalitiesCached();
    if (searchDebounced.length === 0) return statesBRA;
    const searchWithoutAccents = formatStringToWithoutAccents(searchDebounced).toLocaleLowerCase();

    const statesIdWithMunicipalitiesFiltered = allMunicipalitiesCached?.map(([stateInfo, municipalities]) => {
      const municipalitiesTyped = municipalities as DivisionsRow[]

      const containsMunicipality = municipalitiesTyped.find((municipality) => {
        const municipalityNameWithoutAccents = formatStringToWithoutAccents(municipality.division_name).toLocaleLowerCase();
        return municipalityNameWithoutAccents.includes(searchWithoutAccents);
      })

      if (containsMunicipality) {
        return stateInfo[1];
      }
    }).filter((state) => state !== undefined);

    const statesFiltered = statesBRA?.filter((state) => {
      const containsState = statesIdWithMunicipalitiesFiltered.includes(state.division.id);

      if (containsState) {
        return state;
      }

    })

    statesFiltered?.forEach(state => {
      const stateCode = state.code as StatesMap;
      segmentationActions.setStatesAlloweds(stateCode);
    })

    return statesFiltered
  }, [
    searchDebounced,
    modalCreateNotificationIsOpen,
    statesIsLoaded
  ])

  const municipalitiesInStateActive = useMemo(() => {
    if (!segmentationState.stateSelectedId) return [];
    const allMunicipalitiesCached = getAllMunicipalitiesCached();

    const stateSelectedIndex = allMunicipalitiesCached.findIndex(
      ([stateInfo]) => stateInfo[1] === segmentationState.stateSelectedId
    )

    const allMunicipalitiesInStateSelected = allMunicipalitiesCached?.[stateSelectedIndex]?.[1] as DivisionsRow[];

    if (searchDebounced.length === 0 || !allMunicipalitiesInStateSelected) {
      return allMunicipalitiesInStateSelected.sort((a, b) => a.division_name.localeCompare(b.division_name));
    }

    const searchWithoutAccents = formatStringToWithoutAccents(searchDebounced).toLocaleLowerCase();

    const municipalitiesFiltered = allMunicipalitiesInStateSelected.filter((municipality) => {
      const municipalityNameWithoutAccents = formatStringToWithoutAccents(municipality.division_name).toLocaleLowerCase();
      return municipalityNameWithoutAccents.includes(searchWithoutAccents);
    })

    if (municipalitiesFiltered.length === 0) {
      segmentationActions.setStateClicked(undefined);
      segmentationActions.setStateSelected(undefined);
      segmentationActions.setStateSelectedId(undefined);
      segmentationActions.setMunicipalitySelected(undefined);
      segmentationActions.setMunicipalitySelectedId(undefined);
      return
    }

    const municipalitiesFilteredNames = municipalitiesFiltered.map((municipality) => municipality.division_name);
    segmentationActions.setMunicipalitiesAllowed(municipalitiesFilteredNames);

    const municipalitySelectedIsInMunicipalitiesAllowed =
      municipalitiesFilteredNames.includes(segmentationState.municipalitiesSelected?.[0] as string);

    if (!municipalitySelectedIsInMunicipalitiesAllowed) {
      segmentationActions.setMunicipalitySelected(undefined);
      segmentationActions.setMunicipalitySelectedId(undefined);
    }

    return municipalitiesFiltered.sort((a, b) => a.division_name.localeCompare(b.division_name));
  }, [
    segmentationState.stateSelectedId,
    searchDebounced
  ])

  const allIsLoaded = useMemo(() => {
    return !statesBRAisLoading && statesIsLoaded;
  }, [statesBRAisLoading, statesIsLoaded])

  const getAllMunicipalitiesCountFilteredByStateId = useCallback((stateId: number) => {
    if (!allIsLoaded) return 0;
    const allMunicipalitiesCached = getAllMunicipalitiesCached();

    const municipalitiesFilteredCount = allMunicipalitiesCached.reduce((acc, [stateInfo, municipalities]) => {
      if (stateInfo[1] === stateId && municipalities) {
        return (municipalities as DivisionsRow[]).filter((municipality) => {
          const municipalityNameWithoutAccents = formatStringToWithoutAccents(municipality.division_name).toLocaleLowerCase();
          const searchWithoutAccents = formatStringToWithoutAccents(searchDebounced).toLocaleLowerCase();

          return municipalityNameWithoutAccents.includes(searchWithoutAccents);
        }).length;
      }

      return acc;
    }, 0)

    return municipalitiesFilteredCount;
  }, [
    searchDebounced,
    modalCreateNotificationIsOpen,
    allIsLoaded
  ])

  const getAllMunicipalitiesCountByStateId = useCallback((stateId: number) => {
    if (!allIsLoaded) return 0;

    if (searchDebounced.length > 0) return 0;

    const allMunicipalitiesCached = getAllMunicipalitiesCached();

    const municipalitiesCount = allMunicipalitiesCached.reduce((acc, [stateInfo, municipalities]) => {
      if (stateInfo[1] === stateId && municipalities) {
        return (municipalities as DivisionsRow[]).length;
      }

      return acc;
    }, 0)


    return municipalitiesCount;
  }, [
    modalCreateNotificationIsOpen,
    searchDebounced,
    allIsLoaded
  ])

  useEffect(() => {

    if (segmentationState.municipalitiesSelected?.length === 0) return;

    const indexMunicipalitySelected =
      municipalitiesInStateActive?.findIndex((municipality) => segmentationState.municipalitiesSelected?.includes(municipality.division_name)) || 0;

    if (indexMunicipalitySelected === -1) return;

    accordionListScrollTo(indexMunicipalitySelected);

  }, [segmentationState.municipalitiesSelected])

  useEffect(() => {
    setTimeout(() => {
      scrollListMunicipalities();
    }, 400)
  }, [municipalitiesInStateActive])

  useEffect(() => {
    segmentationActions.setStatesIsLoading(allIsLoaded);
  }, [allIsLoaded])

  useEffect(() => {
    if (!statesBRA) return;
    const municipalitiesCached = getAllMunicipalitiesCached();
    const municipalitiesIsCached =
      municipalitiesCached.length > 0
      && municipalitiesCached.every(([, municipalities]) => Array(municipalities).length > 0);

    if (!municipalitiesIsCached) {
      setMunicipalitiesLoaded(Array(statesBRA.length).fill(false));
      statesBRA.forEach((state, index) => {
        const stateId = state.division.id;

        queryClient.fetchQuery({
          queryKey: ["municipalities-per-state-BRA", stateId],
          queryFn: async () => {
            const response = await getDivisions({
              division_type: ["MUNICIPALITY"],
              parent_id: [stateId],
            })

            setMunicipalitiesLoaded((prev) => {
              return prev.map((loaded, i) => {
                if (i === index) {
                  return true;
                }

                return loaded;
              })
            })

            return response
          },
          staleTime: 1000 * 60 * 60 * 24,
        })
      })
    } else {
      setMunicipalitiesLoaded(Array(statesBRA.length).fill(true));
    }
  }, [statesBRA])

  useEffect(() => {
    if (searchDebounced.length === 0) {
      segmentationActions.setMunicipalitiesAllowed(undefined)
      return
    }
  }, [searchDebounced])

  useEffect(() => {
    if (!segmentationState.stateSelectedId) return;
    accordionStateListScrollTo();
  }, [segmentationState.stateSelectedId, searchDebounced])

  useEffect(() => {
    if (modalCreateNotificationIsOpen) {
      segmentationActions.setSearch("");
      segmentationActions.resetStatesAlloweds();

      setTimeout(() => {
        accordionStateListScrollTo();
      }, 400)
    }
  }, [
    modalCreateNotificationIsOpen
  ])

  return {
    municipalitiesPerState,
    segmentationState,
    segmentationActions,
    onOpenAccordionState,
    onClickInMunicipality,
    statesBRA,
    statesFiltereds,
    getAllMunicipalitiesCountByStateId,
    getAllMunicipalitiesCountFilteredByStateId,
    municipalitiesInStateActive,
    allIsLoaded
  };
}
