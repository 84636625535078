import { useAuth } from "@group-link-one/gl-auth";
import { useI18n, useToast } from "@group-link-one/grouplink-components";
import { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { ErrorType, InfoAirErrors } from "../../../Errors/errors";
import { ILoginData } from "./types/type";

export const useLogin = () => {
  const navigate = useNavigate();
  const { verifyUserAPI } = useAuth();
  const { addToast } = useToast();
  const { t } = useI18n();
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | boolean>(
    ""
  );

  const sendForm = async (data: ILoginData) => {
    try {
      const response = await verifyUserAPI(data);


      // console.log(response);

      // return
      // if (response === true) {
      //   setTimeout(() => {
      //     redirectToMainPage();
      //   }, 1500);
      // }

      if (response.error) {
        setLoginErrorMessage(true);
        throw new AxiosError(response.error.data.error);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const typeError = error.message as ErrorType;

        const { title, message } = new InfoAirErrors({
          error: typeError,
        }).getError();

        addToast({
          title,
          message,
          type: "error",
        });
      }
    }
  };


  const redirectToForgotPassword = () => {
    navigate("/forgot-password");
  };

  const loginFormSchema: yup.ObjectSchema<ILoginData> = yup.object().shape({
    email: yup.string().email().required(t("login.form.errors.email")),
    password: yup.string().required(t("login.form.errors.password")),
  });

  return {
    sendForm,
    redirectToForgotPassword,
    loginFormSchema,
    loginErrorMessage,
  };
};
