import { GLCardTemplate, GLNotificationPreview, GLTypography, useI18n } from "@group-link-one/grouplink-components";

import GovPIImage from "../../../../../images/MessageCenter/notifications/govpiLogo.webp"
import { useSegmentationStore } from "../../../ModalCreateNotification/store/segmentation";
import { useNotificationsStore } from "../../store/notifications-store";
import { NotificationMessage, PopupPreviewContentContainer } from "./PopupPreviewContentStyle";
import { usePopupPreviewNotification } from "./usePopupPreviewContent";

export function PopupPreviewContent() {
  const { t } = useI18n()
  const { androidPhoneImage, iphonePhoneImage } = usePopupPreviewNotification();
  const { state } = useNotificationsStore();
  const { state: segmentationState } = useSegmentationStore()

  return (
    <PopupPreviewContentContainer>
      <div className="popup-content">
        <div className="notification-content">
          <NotificationMessage>
            <GLTypography
              text={t("messageCenter.notifications.preview.title")}
              as="p"
              color="FONT_COLOR_DARK_GREY"
              weight={600}
              fontSize={4}
            />

            <div className="notification-message-content">
              <div>
                <GLTypography
                  text={t("messageCenter.notifications.preview.notificationText")}
                  as="span"
                  fontSize={3}
                  color="FONT_COLOR_DARK_GREY"
                  weight={600}
                />
                <GLTypography
                  text={state.popupPreviewNotificationTitle || t("messageCenter.notifications.preview.notificationTitle")}
                  as="p"
                  fontSize={4}
                  color="FONT_COLOR"
                  weight={600}
                />
              </div>

              <div>
                <GLTypography
                  text={t("messageCenter.notifications.preview.messageBody")}
                  as="span"
                  fontSize={3}
                  color="FONT_COLOR_DARK_GREY"
                  weight={600}
                />
                <GLTypography
                  text={state.popupPreviewNotificationMessage || t("messageCenter.notifications.preview.notificationMessage")}
                  as="p"
                  fontSize={4}
                  color="FONT_COLOR"
                  weight={400}
                  style={{ lineHeight: "24px" }}
                />
              </div>

              <div>
                <GLTypography
                  text={t("messageCenter.notifications.preview.category")}
                  as="span"
                  fontSize={3}
                  color="FONT_COLOR_DARK_GREY"
                  weight={600}
                />

                <GLCardTemplate.Column direction="column" gap={0}>
                  <GLTypography
                    text={state.categoryTitle || t("messageCenter.notifications.preview.categoryTitle")}
                    as="p"
                    fontSize={4}
                    color="FONT_COLOR"
                    weight={600}
                  />
                  <GLTypography
                    text={state.categoryDescription || t("messageCenter.notifications.preview.categoryDescription")}
                    as="p"
                    fontSize={4}
                    color="FONT_COLOR"
                    weight={400}
                  />
                </GLCardTemplate.Column>

              </div>

              <div>
                <GLTypography
                  text={t("messageCenter.notifications.preview.location")}
                  as="span"
                  fontSize={3}
                  color="FONT_COLOR_DARK_GREY"
                  weight={600}
                />

                <GLCardTemplate.Column direction="row" gap={5} justify="flex-start">
                  <GLTypography
                    text={segmentationState.stateSelected || t("messageCenter.notifications.preview.categoryTitle")}
                    as="p"
                    fontSize={4}
                    color="FONT_COLOR"
                    weight={600}
                  />
                  {"·"}
                  <GLTypography
                    text={segmentationState.municipalitiesSelected?.[0] || t("messageCenter.notifications.preview.categoryDescription")}
                    as="p"
                    fontSize={4}
                    color="FONT_COLOR"
                    weight={400}
                  />
                </GLCardTemplate.Column>

              </div>
            </div>
          </NotificationMessage>
        </div>

        <GLNotificationPreview
          notificationImage={GovPIImage}
          notificationTitle={state.popupPreviewNotificationTitle || ""}
          notificationMessage={state.popupPreviewNotificationMessage || ""}
          androidImage={androidPhoneImage}
          iphoneImage={iphonePhoneImage}
        />
      </div>
    </PopupPreviewContentContainer>
  )
}
