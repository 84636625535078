import { GL_COLORS, GLButton, GLModal, useI18n } from "@group-link-one/grouplink-components";

import { useAppContext } from "../Context/useAppContext";
import { useApp } from "../useApp";

export const ModalDeleteApp = () => {
  const { t } = useI18n()
  const { deleteApp } = useApp();
  const { appData, openModal, setOpenModal } = useAppContext();

  return (
    <GLModal
      open={openModal}
      title={t("overview.apps.modal.modalDelete.title")}
      description={`${t("overview.apps.modal.modalDelete.description.one")} "${appData.name}", ${t("overview.apps.modal.modalDelete.description.two")}`}
      content={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            gap: "25px",
          }}
        >
          <GLButton
            style={{
              border: `2px solid ${GL_COLORS.NAVY}`,
              color: `${GL_COLORS.NAVY}`,
              fontWeight: 600,
            }}
            text={t("overview.apps.modal.modalDelete.buttonCancel")}
            align="center"
            variant="outline"
            onClick={() => setOpenModal(false)}
          />
          <GLButton
            text={t("overview.apps.modal.modalDelete.buttonContinue")}
            align="center"
            variant="danger_fill"
            style={{ fontWeight: 600 }}
            onClick={() => {
              deleteApp(appData.id);
              setOpenModal(false);
            }}
          />
        </div>
      }
    />
  );
};
