import { StepProps, useI18n, useToast } from "@group-link-one/grouplink-components";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { useListNotifications } from "../../../../Services/messageCenterService/useListNotifications/useListNotifications";
import { SendNotificationResponseRow } from "../../../../Services/messageCenterService/useListNotifications/useListNotifications.types";
import { useNotificationsStore } from "../../Notifications/store/notifications-store";
import { useCreateNotificationStore } from "../store/createNotification";
import { useSegmentationStore } from "../store/segmentation";
import { useSelectTemplateStore } from "../store/selectTemplate";

export const useModalContent = () => {
  const { t } = useI18n()

  const { state: createNotificationState, actions: createNotificationActions } =
    useCreateNotificationStore();

  const { state: segmentationState, actions: segmentationActions } = useSegmentationStore();
  const { state: notificationsState, actions: notificationsActions } = useNotificationsStore();
  const { state: selectTemplateState, actions: selectTemplateActions } = useSelectTemplateStore()

  const { addToast } = useToast()
  const { sendNotification: sendNotificationFn, createNotification } = useListNotifications()

  const [isSendingNotification, setIsSendingNotification] = useState(false);
  const queryClient = useQueryClient();

  const functionToVerifyIfCanAdvanceMap = {
    segmentation: verifySegmentationIsCorrect,
    "select-template": verifySelectTemplateIsCorrect,
  };

  function formatTextToNextStep(currentStep: StepProps) {
    const hasNextStep = createNotificationState.steps[currentStep.id + 1];

    if (hasNextStep) {
      return hasNextStep.textToHere;
    }

    return t("messageCenter.notifications.creationModal.sendNotification");
  }

  function handleOnClickToAdvanceStep(currentStep: StepProps) {
    const nextStep = createNotificationState.steps[currentStep.id + 1];

    if (nextStep) {
      const verifyFunction =
        functionToVerifyIfCanAdvanceMap[
        currentStep.key as keyof typeof functionToVerifyIfCanAdvanceMap
        ];

      const isError = verifyFunction();

      if (isError) return

      createNotificationActions.setStepActive(nextStep.id);
    } else {
      sendNotification();
    }
  }

  function getParametersToSendNotifications() {
    const parameters = {
      message: notificationsState.popupPreviewNotificationMessage,
      title: notificationsState.popupPreviewNotificationTitle,
      category_id: notificationsState.categoryId,
      message_id: notificationsState.messageId,

      state_id: segmentationState.stateSelectedId,
      municipality_id: segmentationState.municipalitySelectedId,
    };

    return parameters;
  }

  async function sendNotification() {
    const parameters = getParametersToSendNotifications();

    if (!parameters.message_id || !parameters.municipality_id) return

    try {
      setIsSendingNotification(true);
      const sendNotificationResponse = await sendNotificationFn({
        division_id: parameters.municipality_id,
        message_id: parameters.message_id,
      });

      await sendToDelivered(sendNotificationResponse.rows)

      resetAll();
      queryClient.invalidateQueries({
        queryKey: ["get-message-center-notifications-delivered"]
      });

      setTimeout(() => {
        addToast({
          title: t("messageCenter.notifications.toasts.titles.sent"),
          message: t("messageCenter.notifications.toasts.messages.sent"),
          type: "success",
        })
      }, 500);

    } catch (error) {
      addToast({
        title: t("messageCenter.notifications.toasts.titles.errorSendingNotification"),
        message: t("messageCenter.notifications.toasts.messages.errorSendingNotification"),
        type: "error",
      })
    } finally {
      setIsSendingNotification(false);
    }
  }

  async function sendToDelivered(devicesDelivered: SendNotificationResponseRow[]) {
    const parameters = getParametersToSendNotifications();

    const notificationDelivered = {
      title: parameters.title,
      message: parameters.message,
      devicesDelivered: devicesDelivered.length,
      createdAt: new Date().toISOString(),
      sentAt: new Date().toISOString(),
      status: "delivered"
    }

    await createNotification({
      key: `notification-delivered-${new Date().getTime()}`,
      value: notificationDelivered
    })

  }

  function resetAll() {
    createNotificationActions.resetAll();
    segmentationActions.resetAll();
    notificationsActions.resetAll();
    selectTemplateActions.resetAll();
  }

  function verifySegmentationIsCorrect() {
    if (!segmentationState.stateSelectedId) {
      addToast({
        title: t("messageCenter.notifications.toasts.titles.stateNotSelected"),
        message: t("messageCenter.notifications.toasts.messages.stateNotSelected"),
        type: "error",
      })

      return true;
    }

    if (!segmentationState.municipalitySelectedId) {
      addToast({
        title: t("messageCenter.notifications.toasts.titles.municipalityNotSelected"),
        message: t("messageCenter.notifications.toasts.messages.municipalityNotSelected"),
        type: "error",
      })

      return true;
    }

    return false;
  }

  function verifySelectTemplateIsCorrect() {
    if (!selectTemplateState.templateSelectedId) {
      addToast({
        title: t("messageCenter.notifications.toasts.titles.templateNotSelected"),
        message: t("messageCenter.notifications.toasts.messages.templateNotSelected"),
        type: "error",
      })

      return true;
    }

    return false;
  }

  return {
    formatTextToNextStep,
    handleOnClickToAdvanceStep,
    isSendingNotification,
    resetAll
  };
};
