import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { useAppService } from "../../../Services/appService/useAppService";
import { IApp, IAppAndroidVault, IAppData, IAppIosVault } from "./types/app";

export const useApp = () => {
  const { createApp, getApps, countApps, updateApps, deleteApps, appsVault } =
    useAppService();

  const [androidOpen, setAndroidOpen] = useState<boolean>(false);
  const [iosOpen, setIosOpen] = useState<boolean>(false);

  const [androidSelected, setAndroidSelected] = useState<boolean>(false);
  const [iosSelected, setIosSelected] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const registerApp = async (data: IAppData) => {
    const { name, android, ios } = data;

    const appData: IApp = {
      android: android.android,
      ios: ios.ios,
      name: name,
      pkg_name_android:
        (android.android && android.pkg_name_android) || undefined,
      pkg_name_ios: (ios.ios && ios.pkg_name_ios) || undefined,
    };

    const app = await createApp(appData);

    appData.id = app.data.id;

    if (android.android && ios.ios) {
      await Promise.all([
        proccessIos(app.data.id, { ios }),
        proccessAndroid(app.data.id, { android }),
      ]);
    }

    if (ios.ios) await proccessIos(app.data.id, { ios });

    if (android.android) await proccessAndroid(app.data.id, { android });

    const previousApps = queryClient.getQueryData<IApp[]>(["get-apps"]);
    const previousCount = queryClient.getQueryData<number>(["count-apps"]);

    if (previousApps) {
      queryClient.setQueryData<IApp[]>(
        ["get-apps"],
        [appData, ...previousApps],
      );
    }

    queryClient.setQueryData<number>(["count-apps"], previousCount! + 1);

    return app;
  };

  const { data: apps, isLoading } = useQuery({
    queryKey: ["get-apps"],
    queryFn: () => getApps().then((res) => res),
  });

  const { data: count } = useQuery({
    queryKey: ["count-apps"],
    queryFn: () => countApps().then((res) => res),
  });

  const updateApp = async (appId: number, data: Partial<IApp>) => {
    return updateApps(appId, data);
  };

  const deleteApp = async (appId: number) => {
    const previousCount = queryClient.getQueryData<number>(["count-apps"]);

    queryClient.setQueryData(["get-apps"], (value: IApp[]) => {
      return value.filter((app) => app.id !== appId);
    });

    if (previousCount) {
      queryClient.setQueryData(["count-apps"], previousCount - 1);
    }

    return deleteApps(appId);
  };

  const proccessIos = async (
    appId: number,
    iosData: Pick<IAppData, "ios">,
  ): Promise<void> => {
    const reader = new FileReader();

    reader.onload = async (event: any) => {
      const data = event.target.result;

      await appInVault(
        appId,
        {
          key_id: iosData.ios.key_id!,
          team_id: iosData.ios.team_id!,
          private_key: data,
          sandbox: false,
        },
        "apn_identity",
      );
    };

    reader.readAsText(iosData.ios.p8_key_file);

    return;
  };

  const proccessAndroid = async (
    appId: number,
    androidData: Pick<IAppData, "android">,
  ): Promise<void> => {
    const reader = new FileReader();

    reader.onload = async (event: any) => {
      const credentials = event.target.result;

      await appInVault(
        appId,
        {
          credentials_json: credentials,
          sender_id: androidData.android.sender_id!,
        },
        "gcm_identity",
      );
    };

    reader.readAsText(androidData.android.credentials_json);

    return;
  };

  const appInVault = async (
    appId: number,
    data: IAppAndroidVault | IAppIosVault,
    type: string,
  ): Promise<void> => {
    await appsVault({
      namespace: `app:${appId}`,
      data,
      type,
    });
    return;
  };

  return {
    registerApp,
    updateApp,
    deleteApp,
    apps,
    count,
    androidOpen,
    iosOpen,
    isLoading,
    setAndroidOpen,
    setIosOpen,
    androidSelected,
    setAndroidSelected,
    iosSelected,
    setIosSelected,
  };
};
