import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"

import { useGLPagination } from "../../../../contexts/GLPaginationContext/GLPaginationContext"
import { useListNotifications } from "../../../../Services/messageCenterService/useListNotifications/useListNotifications"
import { GetNotificationsDeliveredResponseRow } from "../../../../Services/messageCenterService/useListNotifications/useListNotifications.types"
export const IPP_NOTIFICATIONS = 10


// NotificationsContext.tsx
import React, { createContext, useContext } from "react";

import { TabProps } from "../../../../@types/TabProps"

interface NotificationsContextProps {
  tabs: TabProps[];
  activeTab: TabProps | undefined;
  currentPage: number;
  notifications: GetNotificationsDeliveredResponseRow[];
  notificationsPaginated: GetNotificationsDeliveredResponseRow[];
  notificationsIsLoading: boolean;
  searchTerm: string;
  handleOnPaginate: (page: number) => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NotificationsContext = createContext<NotificationsContextProps | undefined>(undefined);

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error("useNotificationsContext must be used within a NotificationsProvider");
  }
  return context;
};

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const notificationsContent = useNotificationsContent();

  return (
    <NotificationsContext.Provider value={{ ...notificationsContent }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContent = () => {
  const {
    state: { tabs, activeTabId, currentPage },
    actions: { getActiveTabById, setCurrentPage, tabsActions, }
  } = useGLPagination()

  const { getNotificationsDelivered } = useListNotifications()
  const activeTab = getActiveTabById(activeTabId || 1)

  const queryClient = useQueryClient()

  const { data, isLoading: notificationsIsLoading } = useQuery({
    queryKey: ["get-message-center-notifications-delivered"], // , currentPage, search -> terá mais um parâmetro de filtro quando entrar as outras tabs
    queryFn: async () => {

      // const { nextPageToken, optionsToStoreNextPageToken } = getNextPageToken()
      const response = await getNotificationsDelivered()

      const responseFiltered = response.rows
        .filter((notification) => notification.key.includes("notification-delivered-") && notification.value.title)

      tabsActions.setCount(tabs[0], responseFiltered.length)

      // setNextPageToken({
      //   hasMore: response.has_more,
      //   nextPageToken: response.next_page_token,
      //   optionsToStoreNextPageToken,
      // })

      return responseFiltered

    },
    staleTime: 1000 * 60,
  })
  const [notifications, setNotifications] = useState<GetNotificationsDeliveredResponseRow[]>([])
  const [rawNotifications, setRawNotifications] = useState<GetNotificationsDeliveredResponseRow[]>([])
  const [searchTerm, setSearchTerm] = useState("")

  function handleOnPaginate(page: number) {
    setCurrentPage(page)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value)

  const normalizeString = (str: string) => {
    return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  };

  const filterItems = () => {
    const itemsFiltered = notifications.filter((notificationData) => {
      if (notificationData?.value) {
        const { title, message } = notificationData.value
        return normalizeString(title ?? "").includes(normalizeString(searchTerm)) ||
          normalizeString(message ?? "").includes(normalizeString(searchTerm))
      }
    })

    return itemsFiltered
  }

  const notificationsPaginated = useMemo(() => {
    return notifications.slice(currentPage * IPP_NOTIFICATIONS, (currentPage + 1) * IPP_NOTIFICATIONS)
  }, [notifications, currentPage])

  // On SEARCH TERM change AND search term has more than 3 characters and no spaces
  useEffect(() => {
    const searchTermIsValid = searchTerm && searchTerm.trim().length > 3

    if (searchTermIsValid) {
      const itemsFiltered = filterItems()
      setNotifications(itemsFiltered)
      return
    }
    rawNotifications && setNotifications(rawNotifications)
  }, [searchTerm])

  useEffect(() => {
    const notificationsCached = queryClient.getQueryData<GetNotificationsDeliveredResponseRow[]>(["get-message-center-notifications-delivered"])

    if (notificationsCached) {
      setNotifications(notificationsCached)
      setRawNotifications(notificationsCached)

      tabsActions.setCount(tabs[0], notificationsCached.length)
    }

    if (data && !notificationsCached) {
      setNotifications(data)
      setRawNotifications(data)
    }
  }, [data])

  return {
    tabs,
    activeTab,
    notifications,
    currentPage,
    notificationsIsLoading,
    handleOnPaginate,
    handleSearch,
    searchTerm,
    notificationsPaginated
  }
}
