import { ForgotForm } from "./ForgotForm/form";
import { useForgot } from "./useForgot";

export const Forgot = () => {
  const { sendForm, redirectToLogin, forgotSchema, disabled } = useForgot();

  return (
    <ForgotForm
      sendForm={sendForm}
      redirectToLogin={redirectToLogin}
      schema={forgotSchema}
      disabled={disabled}
    />
  );
};
