import { GLCardTemplate, GLTypography, useI18n } from "@group-link-one/grouplink-components"
import { format } from "date-fns";
import { useMediaQuery } from "usehooks-ts";

import { GetNotificationsDeliveredResponseRow } from "../../../../Services/messageCenterService/useListNotifications/useListNotifications.types";
// import { useCreateNotificationStore } from "../../ModalCreateNotification/store/createNotification";
// import { useNotificationsStore } from "../store/notifications-store";
import { NotificationsCardsContainer, NotificationStatusContainer } from "./NotificationsCardsStyle";

interface ApresentationNotificationsCardsProps {
  notifications?: GetNotificationsDeliveredResponseRow[]
}

export function ApresentationNotificationsCards({ notifications }: ApresentationNotificationsCardsProps) {
  const { t } = useI18n()
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <NotificationsCardsContainer>
      {notifications && notifications.map((notification) => (
        <GLCardTemplate.Provider key={notification.id}>
          <GLCardTemplate.Root>

            {!isMobile ? (
              <GLCardTemplate.Header padding="18px 20px">
                <GLCardTemplate.Column width={35} justify="flex-start">
                  <GLTypography
                    text={notification.value.title ?? ""}
                    as="p"
                    color="FONT_COLOR"
                    fontSize={4}
                    weight={600}
                  />
                </GLCardTemplate.Column>

                <GLCardTemplate.Column width={18} direction="column" gap={5}>
                  <GLTypography
                    text={t("messageCenter.notifications.cards.lastTime")}
                    as="span"
                    color="FONT_COLOR_DARK_GREY"
                    fontSize={3}
                    weight={600}
                  />

                  <GLTypography
                    text={format(new Date(notification.value.sentAt), "MM/dd/yyyy")}
                    as="p"
                    color="FONT_COLOR"
                    fontSize={4}
                    weight={400}
                  />
                </GLCardTemplate.Column>

                <GLCardTemplate.Column width={18} direction="column" gap={5}>
                  <GLTypography
                    text={t("organizations.card.createdAt")}
                    as="span"
                    color="FONT_COLOR_DARK_GREY"
                    fontSize={3}
                    weight={600}
                  />

                  <GLTypography
                    text={format(new Date(notification.value.createdAt), "MM/dd/yyyy")}
                    as="p"
                    color="FONT_COLOR"
                    fontSize={4}
                    weight={400}
                  />
                </GLCardTemplate.Column>

                <GLCardTemplate.Column width={18} direction="column" gap={5}>
                  <GLTypography
                    text={t("messageCenter.list.modal.creationModal.steps.four.labels.audience")}
                    as="span"
                    color="FONT_COLOR_DARK_GREY"
                    fontSize={3}
                    weight={600}
                  />

                  <GLTypography
                    text={notification.value.devicesDelivered.toString()}
                    as="p"
                    color="FONT_COLOR"
                    fontSize={4}
                    weight={400}
                  />
                </GLCardTemplate.Column>

                <GLCardTemplate.Column width={18}>
                  <NotificationStatusContainer>
                    <GLTypography
                      text={t("messageCenter.notifications.delivered")}
                      as="p"
                      color="FONT_COLOR"
                      fontSize={3}
                      weight={600}
                    />
                  </NotificationStatusContainer>
                </GLCardTemplate.Column>

              </GLCardTemplate.Header>
            ) : (
              <GLCardTemplate.Header padding="18px 20px" direction="column" align="flex-start">
                <GLCardTemplate.Column>
                  <GLCardTemplate.Column>
                    <GLTypography
                      text={notification.value.title ?? ""}
                      as="p"
                      color="FONT_COLOR"
                      fontSize={4}
                      weight={600}
                    />
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column>
                    <NotificationStatusContainer>
                      <GLTypography
                        text={t("messageCenter.notifications.delivered")}

                        as="p"
                        color="FONT_COLOR"
                        fontSize={3}
                        weight={600}
                      />
                    </NotificationStatusContainer>
                  </GLCardTemplate.Column>
                </GLCardTemplate.Column>

                <GLCardTemplate.Column>
                  <GLCardTemplate.Column direction="column" gap={5}>
                    <GLTypography
                      text={t("messageCenter.notifications.cards.lastTime")}
                      as="span"
                      color="FONT_COLOR_DARK_GREY"
                      fontSize={3}
                      weight={600}
                    />

                    <GLTypography
                      text={format(new Date(notification.value.sentAt), "MM/dd/yyyy")}
                      as="p"
                      color="FONT_COLOR"
                      fontSize={4}
                      weight={400}
                    />
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column direction="column" gap={5}>
                    <GLTypography
                      text={t("organizations.card.createdAt")}
                      as="span"
                      color="FONT_COLOR_DARK_GREY"
                      fontSize={3}
                      weight={600}
                    />

                    <GLTypography
                      text={format(new Date(notification.value.createdAt), "MM/dd/yyyy")}
                      as="p"
                      color="FONT_COLOR"
                      fontSize={4}
                      weight={400}
                    />
                  </GLCardTemplate.Column>
                </GLCardTemplate.Column>


                <GLCardTemplate.Column direction="column" gap={5}>
                  <GLTypography
                    text={t("messageCenter.list.modal.creationModal.steps.four.labels.audience")}
                    as="span"
                    color="FONT_COLOR_DARK_GREY"
                    fontSize={3}
                    weight={600}
                  />

                  <GLTypography
                    text={notification.value.devicesDelivered.toString()}
                    as="p"
                    color="FONT_COLOR"
                    fontSize={4}
                    weight={400}
                  />
                </GLCardTemplate.Column>



              </GLCardTemplate.Header>
            )}

          </GLCardTemplate.Root>
        </GLCardTemplate.Provider>
      ))
      }
    </NotificationsCardsContainer >
  );
}
