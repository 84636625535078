import { GL_COLORS, GLButton, GLCardTemplate, GLTypography, useI18n } from "@group-link-one/grouplink-components";
import { format } from "date-fns";
import { useMediaQuery } from "usehooks-ts";

import { ListNotificationsResponse } from "../../../../../Services/messageCenterService/useListNotifications/useListNotifications.types";
import { useSelectTemplateCards } from "./useSelectTemplateCards";

interface SelectTemplateCardsProps {
  templates?: ListNotificationsResponse[];
}

export function SelectTemplateCards({ templates }: SelectTemplateCardsProps) {
  const { t } = useI18n()
  const { onClickTemplateCard, selectTemplateState, getCategoryById } = useSelectTemplateCards();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <>
      {templates && templates.map((template, index) => (
        <GLCardTemplate.Provider key={index}>
          <GLCardTemplate.Root
            style={{
              backgroundColor: selectTemplateState.templateSelectedId
                && selectTemplateState.templateSelectedId !== template.title ? GL_COLORS.DISABLED_BACKGROUND_COLOR : "inherit",

              opacity: selectTemplateState.templateSelectedId
                && selectTemplateState.templateSelectedId !== template.title ? 0.7 : 1,

              pointerEvents: selectTemplateState.templateSelectedId
                && selectTemplateState.templateSelectedId !== template.title ? "none" : "auto",

              cursor: "pointer"
            }}
          >
            {(isMobile || selectTemplateState.typeList === "grid") ? (
              <GLCardTemplate.Header padding="18px 20px" direction="column" handleActionOnClick={() => onClickTemplateCard(template)}>

                <GLCardTemplate.Column>
                  <GLTypography
                    text={template.title}
                    as="p"
                    color="FONT_COLOR"
                    fontSize={4}
                    weight={600}
                  />
                </GLCardTemplate.Column>

                <GLCardTemplate.Actions
                  disappearOnHover
                  style={{
                    opacity: selectTemplateState.templateSelectedId === template.title ? 0 : "revert-layer",
                  }}
                >

                  <GLCardTemplate.Column direction="column" gap={5}>
                    <GLTypography
                      text={t("messageCenter.notifications.cards.lastTime")}
                      as="span"
                      color="FONT_COLOR_DARK_GREY"
                      fontSize={3}
                      weight={600}
                    />

                    <GLTypography
                      text={format(new Date(template.updated_at), "MM/dd/yyyy")}
                      as="p"
                      color="FONT_COLOR"
                      fontSize={4}
                      weight={400}
                    />
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column direction="column" gap={5}>
                    <GLTypography
                      text={t("messageCenter.notifications.cards.category")}
                      as="span"
                      color="FONT_COLOR_DARK_GREY"
                      fontSize={3}
                      weight={600}
                    />

                    <GLTypography
                      text={`
                      ${getCategoryById(template.category_id).title}${" · "}
                      ${getCategoryById(template.category_id).description}
                      `
                      }
                      as="p"
                      color="FONT_COLOR"
                      fontSize={4}
                      weight={400}
                    />
                  </GLCardTemplate.Column>
                </GLCardTemplate.Actions>


                <GLCardTemplate.Actions
                  justify="flex-end"
                  absolute
                  style={{
                    bottom: "10px",
                    opacity: selectTemplateState.templateSelectedId === template.title ? 1 : "revert-layer",
                  }}

                >
                  <GLButton
                    text={selectTemplateState.templateSelectedId === template.title ? t("common.deselect") : t("common.select")}
                    variant="fill"
                    align="center"
                    weight={600}
                    style={{
                      width: "90%",
                      margin: "0 auto",
                    }}
                  />
                </GLCardTemplate.Actions>
              </GLCardTemplate.Header>
            ) : (
              <GLCardTemplate.Header padding="18px 20px" handleActionOnClick={() => onClickTemplateCard(template)}>

                <GLCardTemplate.Column width={45} justify="flex-start">
                  <GLTypography
                    text={template.title}
                    as="p"
                    color="FONT_COLOR"
                    fontSize={4}
                    weight={600}
                  />
                </GLCardTemplate.Column>

                <GLCardTemplate.Column width={18} direction="column" gap={5}>
                  <GLTypography
                    text={t("messageCenter.notifications.cards.lastTime")}
                    as="span"
                    color="FONT_COLOR_DARK_GREY"
                    fontSize={3}
                    weight={600}
                  />

                  <GLTypography
                    text={format(new Date(template.updated_at), "MM/dd/yyyy")}
                    as="p"
                    color="FONT_COLOR"
                    fontSize={4}
                    weight={400}
                  />
                </GLCardTemplate.Column>

                <GLCardTemplate.Column width={18} direction="column" gap={5}>
                  <GLTypography
                    text={t("messageCenter.notifications.cards.category")}
                    as="span"
                    color="FONT_COLOR_DARK_GREY"
                    fontSize={3}
                    weight={600}
                  />

                  <GLTypography
                    text={`
                      ${getCategoryById(template.category_id).title}${" · "}
                      ${getCategoryById(template.category_id).description}
                      `
                    }
                    as="p"
                    color="FONT_COLOR"
                    fontSize={4}
                    weight={400}
                  />
                </GLCardTemplate.Column>

                <GLCardTemplate.Actions
                  width={15}
                  justify="flex-end"
                  absolute
                  style={{
                    right: "20px",
                    opacity: selectTemplateState.templateSelectedId === template.title ? 1 : "revert-layer",
                  }}

                >
                  <GLButton
                    text={selectTemplateState.templateSelectedId === template.title ? t("common.deselect") : t("common.select")}
                    variant="fill"
                    align="center"
                    weight={600}
                    style={{
                      width: "70%",
                    }}
                  />
                </GLCardTemplate.Actions>

              </GLCardTemplate.Header>
            )}
          </GLCardTemplate.Root>
        </GLCardTemplate.Provider >
      ))
      }
    </>

  )
}
