import { GL_COLORS, GLButton, useI18n } from "@group-link-one/grouplink-components";
import { GLTextField } from "@group-link-one/grouplink-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { useLogin } from "../useLogin";
import { Box, BoxSpanned, ButtonLink, LoginForm, Typography } from "./Style";

export const GLLoginForm = () => {
  const { t } = useI18n();
  const {
    sendForm,
    redirectToForgotPassword,
    loginFormSchema,
    loginErrorMessage,
  } = useLogin();

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(loginFormSchema),
  });

  return (
    <LoginForm onSubmit={handleSubmit(sendForm)}>
      <Typography fontSize={25} fontWeight="600">
        {t("login.title")}
      </Typography>

      <Box>
        <GLTextField
          inputSize="secondary"
          title={t("login.form.email.label")}
          placeholder={t("login.form.email.placeholder")}
          failure={!!errors.email?.message || !!loginErrorMessage}
          failureMessage={errors.email?.message}
          onChange={(e) => setValue("email", e.target.value)}
        />
        <GLTextField
          inputSize="secondary"
          title={t("login.form.password.label")}
          type="password"
          withEye
          placeholder={t("login.form.password.placeholder")}
          failure={!!errors.password?.message || !!loginErrorMessage}
          failureMessage={errors.password?.message || loginErrorMessage}
          onChange={(e) => setValue("password", e.target.value)}
        />
      </Box>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ButtonLink type="button" onClick={redirectToForgotPassword}>
          {t("login.form.forgotPassword")}
        </ButtonLink>

        <GLButton
          align="center"
          text={t("login.form.logInButton")}
          variant="fill"
          type="submit"
          size="primary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          style={{ fontWeight: 600, color: GL_COLORS.FONT_COLOR_VARIANT }}
        />
      </div>

      <BoxSpanned>
        <span>
          <p>
            {t("login.form.footerText.one")} {t("login.form.footerText.two")}{" "}
            {t("login.form.footerText.three")} {t("login.form.footerText.four")}
          </p>
        </span>
      </BoxSpanned>
    </LoginForm>
  );
};
