export type MyAccForm = {
  name?: string;
  phone?: string;
  email?: string;
  last_name?: string;
  job_title?: string;
  organization_name?: string;
};

export const FORM_INITIAL_STATE: MyAccForm = {
  name: "",
  phone: "",
  email: "",
  last_name: "",
  job_title: "",
  organization_name: "",
};
