import {
  GLButton,
  GLMainContent,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useState } from "react";

import { Content } from "./Content/appContent";
import { ModalDeleteApp } from "./Content/modalDeleteApp";
import { AppProvider } from "./Context/appContext";

export const Apps = () => {
  const { t } = useI18n();
  const [openDetailedModal, setOpenDetailedModal] = useState(false);

  function modalToggle(open: boolean) {
    setOpenDetailedModal(open);
  }

  return (
    <>
      <AppProvider>
        <GLMainContent
          content={<Content open={openDetailedModal} setOpen={modalToggle} />}
          subtitle={t("pages.settings.title")}
          title={t("pages.settings.apps")}
          hasList={true}
          rightContent={
            <div style={{ display: "flex", width: "25%", gap: "40px" }}>
              <GLButton
                text={t("app.headerButton")}
                weight={600}
                variant="fill"
                align="center"
                onClick={() => modalToggle(true)}
              />
            </div>
          }
        />
        <ModalDeleteApp />
      </AppProvider>
    </>
  );
};
