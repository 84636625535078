import { GL_COLORS } from "@group-link-one/grouplink-components";
import styled from "styled-components";

export const SegmentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .segmentation-content {
    /* display: grid; */
    /* grid-template-columns: 0.45fr 0.55fr; */
    display: flex;

    min-height: 600px;

    border: 1px solid ${GL_COLORS.BORDER_COLOR};
    border-radius: 16px;
    overflow: hidden;

    .segmentation-location {
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 0.45;

      .location-header {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .location-list {
        flex: 1;

        .list-content {
          max-height: 350px;
          overflow-y: auto;
        }
      }
    }

    .segmentation-map {
      flex: 0.55;
      .gl-map-container {
        transform: scale(0.8);
      }
    }
  }


  @media (max-width: 1300px) {
    .segmentation-content {
      flex-direction: column;
    }

  }

  @media (max-width: 1024px) {
    padding-bottom: 120px;
  }

  @media (max-width: 768px) {
    .segmentation-content {
      .segmentation-map {
        display: none;
      }
    }
  }
`;

export const ButtonCleanSearch = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

`
