import { GLMainContent, useI18n } from "@group-link-one/grouplink-components";

import { TabProps } from "../../../@types/TabProps";
import { GLPaginationProvider } from "../../../contexts/GLPaginationContext/GLPaginationContext";
import { ModalCreateNotification } from "../ModalCreateNotification/ModalCreateNotification";
import { NotificationsContent } from "./Content/NotificationsContent";
import { NotificationsProvider } from "./Content/useNotificationsContent";
import { NotificationsRightContent } from "./RightContent/NotificationsRightContent";
// import { PopupPreviewNotification } from "./PopupPreview/PopupPreviewNotification";

export function Notifications() {
  const { t } = useI18n()

  const tabs: TabProps[] = [
    {
      id: 1,
      cacheKey: "message-center-notifications-delivered",
      name: t("messageCenter.notifications.delivered"),
      count: 0,
      active: true,
      onClick: () => { },
    }
  ]

  const lastNextPageToken = {
    "message-center-notifications-delivered": {
      token: undefined,
      page: undefined,
    }
  }

  return (
    <GLPaginationProvider tabs={tabs} lastNextPageToken={lastNextPageToken}>
      <NotificationsProvider>
        <GLMainContent
          content={<NotificationsContent />}
          rightContent={<NotificationsRightContent />}
          subtitle={t("messageCenter.title")}
          title={t("messageCenter.notifications.notifications")}
          hasList={true}
        />

        <ModalCreateNotification />
      </NotificationsProvider>
      {/* <PopupPreviewNotification /> */}
    </GLPaginationProvider>
  );
}
